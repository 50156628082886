/* eslint-disable no-shadow */
import { useContext, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";

import { setDomainConfig } from "@onlinesales-ai/app-v2/domainConfig";
import { pollData as smmPollDataAction } from "@onlinesales-ai/smm-common-v2/store";
import { getDefaultRoute } from "@onlinesales-ai/util-methods-v2";

import {
  fetchPropertySetting as fetchPropertySettingAction,
} from "@onlinesales-ai/app-v2/application";
import { fetchUserAgencyPermissions as fetchUserAgencyPermissionsAction } from "@onlinesales-ai/client-v2";
import { AccessControlContext } from "@onlinesales-ai/access-control-v2";
import { triggerMonitor } from "@onlinesales-ai/error-catcher-v2";

import { getDomainConfiguration } from "src/domainConfig/configuration";
import { redirectUrl as redirectUrlAction, setAppLevelDataFetchState } from "src/store/application";
import { routes } from "src/utilities/constants";

const ClientLevelData = ({
  smmPollData,
  postIds,
  location,
  allowedRoutes,
  redirectUrl,
  appAppLevelData,
  fetchPropertySetting,
  fetchUserAgencyPermissions,
  userPermissions,
}) => {
  const { setPermissions } = useContext(AccessControlContext);

  const { userInfo } = useSelector((state) => {
    return state.Application || {};
  });
  const dispatch = useDispatch();

  const appLevelActionsEnum = {
    PROPERTY_SETTINGS: fetchPropertySetting,
    AGENCY_USER_PERMISSIONS: fetchUserAgencyPermissions,
  };

  const fetchAppLevelData = async () => {
    try {
      if (appAppLevelData?.dataToFetch?.length) {
        const allActions = appAppLevelData.dataToFetch.map((key) => {
          const config = appAppLevelData.config[key];
          const action = appLevelActionsEnum[config.action];
          return action({
            ...config,
            redirectUrl,
          });
        });
        await Promise.all(allActions);
      }
    } catch (err) {
      const { errorCode } = err || {};
      if (errorCode === "AD0000") {
        redirectUrl(routes.ACCESS_DENIED.path);
      } else {
        triggerMonitor("SEV2", "APP_LEVEL_CONFIG_DATA_ERROR", {
          err,
          payload: {
            appAppLevelData,
          },
        });
        triggerMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
          calledFrom: "fetchAppLevelData",
        });
        redirectUrl(routes.DOWNTIME.path);
      }
    } finally {
      dispatch(setAppLevelDataFetchState(false, false));
    }
  };

  useEffect(() => {
    const poll = smmPollData({ isAgency: true });

    poll.startPoll();

    return () => {
      poll.stopPoll();
    };
  }, [postIds]);

  useEffect(() => {
    async function getConfig() {
      if (!_isEmpty(userInfo)) {
        const config = await getDomainConfiguration({});
        dispatch(setDomainConfig(config));
      }
    }
    getConfig();
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.id) {
      fetchAppLevelData();
    }
  }, [userInfo]);

  const checkIfRouteMatch = (routes = []) => {
    let isMatch = false;

    for (let i = 0; i < routes.length; i++) {
      if (routes[i] && location.pathname.includes(routes[i])) {
        isMatch = true;
        break;
      }
    }

    return isMatch;
  };

  useEffect(() => {
    if (allowedRoutes?.length && location && !checkIfRouteMatch(allowedRoutes)) {
      redirectUrl(getDefaultRoute() || routes?.DASHBOARD?.path);
    }
  }, [allowedRoutes]);

  useEffect(() => {
    setPermissions({
      permissions: userPermissions,
    });
  }, [userPermissions]);

  return null;
};

const mapStateToProps = (state) => {
  return {
    postIds: state.SMMPosts.postIds,
    location: state.router?.location || {},
    allowedRoutes: state.DomainConfig.allowedRoutes || [],
    appAppLevelData: state.DomainConfig?.appAppLevelData,
    userPermissions: state?.EntityApplication?.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrl: redirectUrlAction,
      smmPollData: smmPollDataAction,
      fetchPropertySetting: fetchPropertySettingAction,
      fetchUserAgencyPermissions: fetchUserAgencyPermissionsAction,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLevelData);
