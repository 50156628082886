import proxy from "./proxy";
import proxyStaging from "./proxy-staging";
import proxyStaging2 from "./proxy-staging-2";
import proxyTest from "./proxy-test";

export const DEFAULT_PROXY_KEY = "default";

export const getProxyKey = () => {
  const domainName = window.location.hostname;
  const domainMapping = {
    "tving-dev-pulse.onlinesales.ai": "staging2",
    "tving-qa-pulse.onlinesales.ai": "staging2",
    "oms-dev-adops.tving.com": "staging2",
    "oms-qa-staging-2.onlinesales.ai": "staging2",
    "oms-qa-staging-3.onlinesales.ai": "staging2",

    "hotstar-admin.onlinesales.ai": "staging",
    "hotstar-staging-admin.onlinesales.ai": "test",
    "hotstar-sd-admin.onlinesales.ai": "staging",
    "publisher-demo-admin.onlinesales.ai": "staging",
  };

  if (domainMapping[domainName]) {
    return domainMapping[domainName];
  }

  return DEFAULT_PROXY_KEY;
};

export default {
  default: proxy,
  test: proxyTest,
  staging: proxyStaging,
  staging2: proxyStaging2,
};
