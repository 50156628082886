import React, { useMemo } from "react";
import { connect } from "react-redux";
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _set from "lodash/set";
import _get from "lodash/get";
import _omit from "lodash/omit";
import _isUndefined from "lodash/isUndefined";
import _sortBy from "lodash/sortBy";

import { withTranslation } from "react-i18next";

import { Button } from "@onlinesales-ai/button-v2";
import {
  configHoc,
  UserError,
  computeQuery,
  removeUndefinedAndNull,
  cleanEmptyKeysFromObject,
  replacePlaceholders,
  customMergeOS,
} from "@onlinesales-ai/util-methods-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";

import defaultFormComponents from "./components";

import "./index.less";

class Form extends React.Component {
  constructor(props) {
    super(props);

    let values = {};
    this.oldData = React.createRef();

    try {
      values = _cloneDeep(props.values || {});
      if (_isEmpty(_omit(values, props.keysToIgnoreInFormValues))) {
        values = _cloneDeep(props.initialValues || {});
      }
    } catch (err) {
      values = {};
    }

    this.applyRequiredValues(props, values);
    // Setting inital values in oldData for dirtydata Check
    if (this.oldData) {
      this.oldData.current = {
        values,
      };
    }

    this.state = {
      values,
      errors: {},
      showErrors: props.showErrorByDefault,
      childComponentProps: {},
      isPostLoading: false,
    };

    this.componentRefs = {};
  }

  applyRequiredValues = (props, values) => {
    if (!_isEmpty(props.requiredValue)) {
      Object.keys(props.requiredValue).forEach((k) => {
        _set(values, k, props.requiredValue[k]);
      });
    }

    return values;
  };

  UNSAFE_componentWillMount() {
    const { onWillMount } = this.props;

    if (onWillMount) {
      onWillMount(this.state.values);
    }
  }

  UNSAFE_componentWillMount() {
    this.onPropsChange(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.onPropsChange(props, false);
  }

  assignFormRefValues = () => {
    const { formRef } = this.props;

    if (formRef) {
      formRef.current = {
        ...formRef.current,
        submitForm: this.onClickSubmit,
        onChange: this.onChangeFieldValue,
        onError: this.onChangeFieldError,
        resetForm: this.onResetForm,
        getValues: this.getFormValue,
        getIsDirtyData: this.getIsDirtyData,
        checkIfError: this.checkIfError,
        renderComponent: this.renderComponent,
      };
    }
  };

  componentDidMount() {
    this.assignFormRefValues();
  }

  componentDidUpdate() {
    this.assignFormRefValues();
  }

  onResetForm = () => {
    this.setState({
      values: this.props.initialValues || {},
      errors: {},
      showErrors: this.props.showErrorByDefault,
      isPostLoading: false,
    });
  };

  onPropsChange = (props, forceUpdate) => {
    if (
      !_isEmpty(props.values) &&
      (forceUpdate ||
        !_isEqual(props.values, this.props.values) ||
        (props.controlled && !_isEqual(props.values, this.state.values)))
    ) {
      const valuesFromProp = this.applyRequiredValues(props, _cloneDeep(props.values));
      this.setState({
        values: valuesFromProp,
      });
      if (this.oldData) {
        this.oldData.current = {
          values: valuesFromProp,
        };
      }
    }
    if (props.showErrorByDefault !== this.props.showErrorByDefault) {
      this.setState({
        showErrors: props.showErrorByDefault,
      });
    }
  };

  getComponentRef = (key) => {
    if (key && !this.componentRefs[key]) {
      this.componentRefs[key] = React.createRef();
    }

    return this.componentRefs[key];
  };

  onChangeFieldValue = (value, callBack, option, component) => {
    let valToUpdate = { ...value };
    const {
      onFieldChange, beforeFieldChange, showConfirmationModal, resetConfirmationModal,
      formRef,
    } =
      this.props;
    const { values: formValues, errors } = this.state;

    let errorsToUpdate = null;
    let showConfirmation = false;

    if (!option?.skipOnChange) {
      if (beforeFieldChange || formRef?.current?.beforeFieldChange) {
        const funcToCall = beforeFieldChange || formRef?.current?.beforeFieldChange;
        const { procedWithChange, extraValueToAdd } = funcToCall(valToUpdate);

        if (!procedWithChange) {
          return;
        }

        valToUpdate = {
          ...valToUpdate,
          ...extraValueToAdd,
        };
      }
    }

    if (component?.props?.resetValueOnChange?.length) {
      errorsToUpdate = {};

      const { resetValueOnChange, doNotResetErrorOnChange } = component.props;

      resetValueOnChange.forEach((resetObj) => {
        valToUpdate[resetObj.key] = resetObj?.valueToSet;

        if (!showConfirmation) {
          let val = _get(formValues, resetObj.key);

          if (typeof val === "object") {
            val = removeUndefinedAndNull(val);

            if (_isEmpty(val)) {
              val = undefined;
            }
          }

          showConfirmation = typeof val !== "undefined";
        }

        if (!doNotResetErrorOnChange) {
          Object.keys(errors).forEach((errorKey) => {
            if (resetObj?.operation === "STARTS_WITH" && errorKey.startsWith(resetObj?.key)) {
              errorsToUpdate[errorKey] = null;
            }
            if (resetObj?.operation === "EQUALS" && errorKey === resetObj?.key) {
              errorsToUpdate[errorKey] = null;
            }
          });
        }
      });
    }

    const setDate = () => {
      if (errorsToUpdate) {
        this.onChangeFieldError(errorsToUpdate);
      }

      this.setState(
        (prevState) => {
          const copyState = option?.skipClone
            ? { ...prevState.values }
            : _cloneDeep(prevState.values);

          const newVal = Object.keys(valToUpdate).reduce(
            (acc, key) => _set(acc, key, valToUpdate[key]),
            copyState,
          );

          return {
            ...prevState,
            values: newVal,
          };
        },
        () => {
          if (!option?.skipOnChange) {
            if (onFieldChange) {
              onFieldChange(this.state.values, valToUpdate);
            }
            if (formRef?.current?.onFieldChange) {
              formRef?.current?.onFieldChange(this.state.values, valToUpdate);
            }
          }
          if (callBack) {
            callBack();
          }
        },
      );
    };

    if (showConfirmation) {
      showConfirmationModal({
        isShow: true,
        title: `Data will reset. Are you sure you want to change this?`,
        rightBtnText: "No",
        actionBtnText: "Yes",
        actionBtnCallback: () => {
          resetConfirmationModal();
          setDate();
        },
        rightBtnCallback: () => {
          resetConfirmationModal();
        },
      });
    } else {
      setDate();
    }
  };

  onChangeFieldError = (error, callBack) => {
    const { onErrorChange } = this.props;

    this.setState(
      (prevState) => {
        const errors = {
          ...prevState.errors,
          ...error,
        };
        return {
          ...prevState,
          errors: _omit(
            errors,
            Object.keys(errors).filter((e) => !errors[e]),
          ),
        };
      },
      () => {
        if (onErrorChange) {
          onErrorChange(this.state.errors, error);
        }
        if (callBack) {
          callBack();
        }
      },
    );
  };

  getFormValue = () => {
    const { componentList, isTrimFormBeforeSubmit } = this.props;
    let { values } = this.state;

    if (isTrimFormBeforeSubmit) {
      values = {
        ...values,
      };

      componentList.forEach(({ componentType, props, ...rest }) => {
        const { dataKey, isDoNotTrim = false } = props;
        const componentValue = _get(values, dataKey);

        if (!isDoNotTrim) {
          if (componentType === "InputText" && dataKey && typeof componentValue === "string") {
            _set(values, dataKey, componentValue.trim());
          } else if (
            typeof this.componentRefs[dataKey]?.current?.getCustomTrimValue === "function"
          ) {
            _set(
              values,
              dataKey,
              this.componentRefs[dataKey]?.current?.getCustomTrimValue(componentValue),
            );
          }
        }
      });
    }

    return values;
  };

  getIsPartiallyFilled = (overrides = {}) => {
    const { componentList: propsComponentList } = this.props;
    const { values: stateValues } = this.state;

    const componentList = overrides?.componentList || propsComponentList;
    const values = overrides?.values || stateValues;

    const checkData = (formValues, props) => {
      if (!props?.dataKey) {
        return true;
      }

      const val = _get(formValues, props.dataKey);

      if (typeof val === "boolean") {
        return true;
      }

      if (typeof val === "object") {
        return !_isEmpty(val);
      }

      if (_isUndefined(val)) {
        return false;
      }

      return !!val;
    };

    return componentList.some(({ props, partialFilledCheckType }) => {
      if (partialFilledCheckType === "SUB_COMPONENTS_ARRAY" && props?.componentList?.length) {
        const cValues = _get(values, props?.dataKey);

        if (cValues && cValues?.length) {
          return cValues.some((aValue) => {
            return props.componentList.some(({ props: cProps }) => {
              return checkData(aValue, cProps);
            });
          });
        }

        return false;
      }
      if (partialFilledCheckType === "NUMERIC_DATA_LIST" && props?.maxNumberOfFields) {
        const { parentDataKey, dataKey } = props;
        let isPartiallyFilled = false;

        for (let index = 0; index < props?.maxNumberOfFields; index++) {
          isPartiallyFilled = checkData(values, {
            dataKey: `${parentDataKey}.${dataKey}${index + 1}`,
          });
          if (isPartiallyFilled) {
            return isPartiallyFilled;
          }
        }

        return false;
      }

      return checkData(values, props);
    });
  };

  getIsDirtyData = (overrides = {}) => {
    const { values: stateValues } = this.state;
    const { componentList } = this.props;

    const values = overrides?.values || stateValues;

    if (overrides.cleanData) {
      const dirtyDataToExlcude = componentList.filter((c) => c.props.doNotCheckForDirty);
      let newValues = cleanEmptyKeysFromObject(removeUndefinedAndNull(_cloneDeep(values)));
      let newValueToCheck = cleanEmptyKeysFromObject(
        removeUndefinedAndNull(_cloneDeep(this.oldData.current?.values)),
      );

      if (dirtyDataToExlcude.length) {
        dirtyDataToExlcude.forEach((component) => {
          const { dataKey } = component.props;
          newValues = _omit(newValues, dataKey);
          newValueToCheck = _omit(newValueToCheck, dataKey);
        });
      }

      const hasCommonComponentConfig = componentList.filter((c) => c.props.commonComponentConfig);

      if (hasCommonComponentConfig) {
        componentList.forEach((component) => {
          const { doNotCheckForDirty, commonComponentConfig, dataKey } = component.props;
          if (!doNotCheckForDirty && commonComponentConfig) {
            Object.values(commonComponentConfig).forEach((config) => {
              if (config?.props?.doNotCheckForDirty) {
                const getList = _get(newValues, dataKey, []);

                getList.forEach((item, index) => {
                  newValues = _omit(newValues, `${dataKey}[${index}].${config.props.dataKey}`);
                  newValueToCheck = _omit(
                    newValueToCheck,
                    `${dataKey}[${index}].${config.props.dataKey}`,
                  );

                  if (config.props.dataKeyForDropdown) {
                    newValues = _omit(
                      newValues,
                      `${dataKey}[${index}].${config.props.dataKeyForDropdown}`,
                    );
                    newValueToCheck = _omit(
                      newValueToCheck,
                      `${dataKey}[${index}].${config.props.dataKeyForDropdown}`,
                    );
                  }
                });
              }
            });
          }
        });
      }

      return !_isEqual(
        cleanEmptyKeysFromObject(newValues),
        cleanEmptyKeysFromObject(newValueToCheck),
      );
    }

    return !_isEqual(values, this.oldData.current?.values);
  };

  checkFieldsRuntimeError = async (config) => {
    const refKeys = Object.keys(this.componentRefs);
    for (let i = 0; i < refKeys.length; i++) {
      const key = refKeys[i];
      if (typeof this.componentRefs[key]?.current?.onValidate === "function") {
        await new Promise((resolve) => {
          this.componentRefs[key].current.onValidate(resolve, config);
        });
      }
    }
  };

  checkIfError = async (showErr = true, config) => {
    await this.checkFieldsRuntimeError(config);
    let isError = false;
    const { errors } = this.state;
    const {
      showToastOnFieldError,
      showToastMessage,
      errorMsgForFieldError,
      getErrorMsgForFieldError,
      formToastInvalidClass,
    } = this.props;

    if (!_isEmpty(_omit(errors, config?.keysToIgnore || []))) {
      if (showErr) {
        this.setState({ showErrors: true });
      }
      isError = true;

      if (showToastOnFieldError) {
        let message;
        if (typeof getErrorMsgForFieldError === "function") {
          message = getErrorMsgForFieldError(errors);
        }
        showToastMessage({
          type: "ERROR",
          messageToDisplay: message || errorMsgForFieldError,
          actionButtonLabel: null,
          toastDuration: 5000,
          className: formToastInvalidClass || "pendo-track-form-empty-toast-error",
        });
      }
    }

    return isError;
  };

  promiseFailed = (errors) => {
    try {
      return Promise.reject(new UserError(JSON.stringify(errors)));
    } catch (err) {
      return Promise.reject(errors);
    }
  };

  onClickSubmit = async (event, data = {}) => {
    const { values, errors } = this.state;
    const {
      onSubmit,
      beforeSubmit,
      onError,
      resetOnSumbit,
      componentList,
      isTrimFormBeforeSubmit = false,
    } = this.props;

    if (await this.checkIfError()) {
      onError(errors);
      return this.promiseFailed(errors);
    }

    let payload = this.getFormValue();

    if (beforeSubmit) {
      try {
        payload = await beforeSubmit(payload, data);
      } catch (err) {
        return this.promiseFailed(err);
      }
    }

    this.setState({
      isPostLoading: true,
    });

    let hasApiError = false;
    try {
      await onSubmit(payload, data);
    } catch (err) {
      hasApiError = true;
    }

    if (resetOnSumbit) {
      this.onResetForm();
    }

    this.setState({
      isPostLoading: false,
    });

    return hasApiError;
  };

  submitDataOnEnter = async () => {
    const { isPostLoading } = this.state;

    if (!isPostLoading) {
      try {
        await this.onClickSubmit();
      } catch (err) {}
    }
  };

  renderComponent = ({ component, props, overrides }) => {
    const {
      editMode,
      isEditable,
      childComponentProps,
      labelColumns,
      formComponents,
      componentList,
      isDisabled,
      formValueKeyName,
      formRef,
    } = this.props;
    const { errors, values, showErrors } = this.state;

    const Comp = formComponents[component.componentType];
    const componentProps = component.props || {};
    const notEditableinEditMode = component.notEditableinEditMode || false;

    if (component.doNotShowIfEditMode && editMode) {
      return null;
    }

    if (component.doNotShowIfNotEditMode && !editMode) {
      return null;
    }

    if (
      !component.alreadyCheckedShowIf &&
      component.showIf &&
      !computeQuery({ query: component.showIf, values })
    ) {      
      return null;
    }

    if (!Comp) {
      throw new Error(`Form component not found. please add ${component.componentType} component.`);
    }

    let formIsEditable = isEditable;

    if (formIsEditable && notEditableinEditMode) {
      formIsEditable = !(editMode && notEditableinEditMode);
    }

    const componentDataKey = overrides?.dataKey || componentProps.dataKey;

    const extraProps = {};

    if (formValueKeyName) {
      extraProps[formValueKeyName] = values;
    }

    return (
      <Comp
        ref={this.getComponentRef(componentDataKey || component.componentType)}
        key={`${overrides?.keyPrefix || ""}${componentDataKey || component.componentType}`}
        onChange={(value, callBack, option) => this.onChangeFieldValue(value, callBack, option, component)}
        onError={this.onChangeFieldError}
        formValues={values}
        formErrors={errors}
        showErrors={showErrors}
        editMode={editMode}
        isEditable={formIsEditable}
        isDisabled={isDisabled}
        formComponents={formComponents}
        labelColumns={labelColumns}
        submitFormData={this.onClickSubmit}
        getIsDirtyData={this.getIsDirtyData}
        checkIfFormError={this.checkIfError}
        resetForm={this.onResetForm}
        renderComponent={this.renderComponent}
        getIsPartiallyFilled={this.getIsPartiallyFilled}
        renderFormComponent={this.renderFormComponent}
        componentList={componentList}
        submitDataOnEnter={this.submitDataOnEnter}
        formRef={formRef}
        {...componentProps}
        {...childComponentProps}
        {...props}
        {...overrides}
        {...extraProps}
      />
    );
  };

  shouldRender = (id) => {
    const { componentList } = this.props;
    const { values } = this.state;

    const component = componentList.find((component) => component.id === id);

    if (component) {
      if (component.showIf) {
        return computeQuery({ query: component.showIf, values });
      }
      return true;
    }

    return false;
  };

  renderFormComponent = (id, { props } = {}) => {
    const { componentList } = this.props;

    const componentToRender = componentList.find((component) => component.id === id);

    if (!componentToRender) {
      return null;
    }

    return this.renderComponent({ component: componentToRender, props });
  };

  renderCTAButton = () => {
    const {
      stickyFooter,
      enableBoxShadow,
      ctaText,
      isSubmitDisable,
      renderCTA,
      isCTALoading,
      ctaButtonProps,
      footerRight,
      hideCtaSection,
    } = this.props;
    const { values, isPostLoading, errors } = this.state;

    return (
      <div
        className={`cta-container ${footerRight ? "text-right" : "text-center"} ${
          stickyFooter ? "is-sticky-footer" : ""
        } ${enableBoxShadow ? "boxshadow-footer" : ""} ${hideCtaSection ? "d-none" : ""}`}
      >
        {renderCTA ? (
          renderCTA({
            isLoading: isPostLoading || isCTALoading,
            disabled: isPostLoading || isSubmitDisable || isCTALoading,
            values,
            onClickSubmit: this.onClickSubmit,
            checkIfError: this.checkIfError,
            getValues: this.getFormValue,
            isPartiallyFilled: this.getIsPartiallyFilled,
            ctaText,
            formErrors: errors,
          })
        ) : (
          <Button
            {...ctaButtonProps}
            isLoading={isPostLoading || isCTALoading}
            disabled={isPostLoading || isSubmitDisable || isCTALoading}
            onClick={async (event) => {
              try {
                await this.onClickSubmit(event);
              } catch (err) {}
            }}
          >
            {ctaText}
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      wrapperClassName,
      bodyWrapperClass = "",
      lastComponent,
      fistComponent,
      customFormRenderer,
      componentList,
      childComponentProps,
    } = this.props;

    const { values } = this.state;

    const bodyWrapperProps = {
      className: `form-section-body ${bodyWrapperClass}`,
    };

    return (
      <div className={`form-section form-fields-with-label ${wrapperClassName}`}>
        {customFormRenderer ? (
          customFormRenderer({
            shouldRender: this.shouldRender,
            renderFormComponent: this.renderFormComponent,
            renderCTAButton: this.renderCTAButton,
            bodyWrapperProps,
            fistComponent,
            lastComponent,
            formValues: values,
            childComponentProps,
          })
        ) : (
          <>
            <div {...bodyWrapperProps}>
              {fistComponent || null}
              {componentList.map((component) => this.renderComponent({ component }))}
              {lastComponent || null}
            </div>
            {this.renderCTAButton()}
          </>
        )}
      </div>
    );
  }
}

Form.defaultProps = {
  componentList: [],
  labelColumns: 4,
  childComponentProps: {},
  ctaText: "Submit",
  isEditable: true,
  editMode: false,
  stickyFooter: false,
  enableBoxShadow: false,
  formComponents: defaultFormComponents,
  wrapperClassName: "",
  isSubmitDisable: false,
  renderCTA: null,
  onSubmit: () => Promise.reject(),
  onError: () => {},
  controlled: false,
  showErrorByDefault: false,
  isCTALoading: false,
  errorMsgForFieldError: "Please complete all required fields.",
  keysToIgnoreInFormValues: [],
  footerRight: false,
  hideCtaSection: false,
};

const FormWrapper = ({ componentList, agencySettings, ...props }) => {
  const newComponentList = useMemo(() => {
    return componentList.map((component) => {
      if (agencySettings && component?.props?.ruleBasedProps) {
        const { ruleBasedProps, defaultValidations = [] } = component?.props || {};
        const newValidations = [...defaultValidations];
        let propsToAdd = {};

        Object.keys(ruleBasedProps).forEach((ruleId) => {
          const {
            addIf,
            order,
            validations,
            props: ruleProps,
            placeholderValue,
          } = ruleBasedProps[ruleId];

          const computedPlaceholderValue = placeholderValue
            ? replacePlaceholders(placeholderValue, agencySettings)
            : {};

          const shouldAdd = computeQuery({
            query: addIf,
            values: agencySettings,
            placeholderValue: computedPlaceholderValue,
          });

          if (shouldAdd) {
            if (validations?.length) {
              newValidations.push(
                ...replacePlaceholders(
                  validations.map((v) => ({ ...v, order, refId: ruleId })),
                  { ...agencySettings, placeholderValue: computedPlaceholderValue },
                ),
              );
            }

            if (ruleProps) {
              propsToAdd = {
                ...propsToAdd,
                ...replacePlaceholders(ruleProps, {
                  ...agencySettings,
                  placeholderValue: computedPlaceholderValue,
                }),
              };
            }
          }
        });

        return {
          ...component,
          props: {
            ...customMergeOS({}, _cloneDeep(component.props), propsToAdd),
            validations: _sortBy(newValidations, "order"),
          },
        };
      }

      return component;
    });
  }, [componentList, agencySettings]);

  return <Form componentList={newComponentList} {...props} />;
};

const mapStateToProps = (state) => {
  return {
    agencySettings: state.Application?.agencySettings,
  };
};

export default connect(mapStateToProps)(
  withTranslation()(configHoc(OSHOCWithUtilities(FormWrapper), { isForm: true })),
);
