import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AddOnsHandler, DownloaderBar, HelpSection, OfflineToast } from "@onlinesales-ai/addons-handler-v2";
import { swithToNewConfirmartion, swithToNewToast } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { GlobalSearch } from "@onlinesales-ai/global-search-v2";
import { ClientSelection, LanguageSelection } from "@onlinesales-ai/utils-components-v2";
import {
  getCookie,
  setLSItem,
  getLSItem,
  pendoTrackEvent,
  setCookie,
} from "@onlinesales-ai/util-methods-v2";

import { redirectUrl } from "src/store/application";
import { configOverrides, isStagingDomain, isLngDomain } from "src/domainConfig/configuration";

import "./addOns.less";

swithToNewConfirmartion();
swithToNewToast();

const AddOns = ({
  redirect,
  userInfo,
  helpSection,
  isInternalUser,
  isPulseUI,
  doNotRedirectToPulse,
  preferredTheme,
}) => {
  const isLoggedIn = useMemo(() => {
    return !!userInfo?.id;
  }, [userInfo]);

  useEffect(() => {
    if (!isInternalUser && !isPulseUI) {
      try {
        let browserInstanceIdentity = getLSItem("browserInstanceIdentity");
        if (!browserInstanceIdentity) {
          browserInstanceIdentity = new Date().getTime();
          setLSItem("browserInstanceIdentity", browserInstanceIdentity);
        }

        pendoTrackEvent("LOG_BROWSER_INSTANCE", {
          browserInstanceIdentity,
          userAgent: window?.navigator?.userAgent,
        });
      } catch (err) {}
    }
  }, [isInternalUser, isPulseUI]);

  useEffect(() => {
    if (NODE_ENV === "production" && !isPulseUI && !doNotRedirectToPulse) {
      try {
        const moveToLegacyPortal = getCookie("move_to_legacy_portal_v2");

        if (moveToLegacyPortal === true) {
          setCookie("move_to_legacy_portal_v2", false);
        } else {
          // window.open(window.location.origin.replace("-admin", "-pulse"), "_self");
        }
      } catch (err) {}
    }
  }, [isPulseUI, doNotRedirectToPulse]);

  useEffect(() => {
    if (window.Beamer && typeof window.Beamer.update === "function") {
      window.Beamer.update({ theme: preferredTheme === "dark" ? "dark" : "" });
    }
  }, [preferredTheme]);

  return (
    <AddOnsHandler isAdminAccount isInitPWA={false} redirectUrl={redirect}>
      <DownloaderBar />
      <OfflineToast />
      <div className="addons-floating-btn-wrapper">
        <div className="addons-floating-btn-inner">
          {!!helpSection?.isShow && isLoggedIn && (
            <HelpSection redirectUrl={redirect} {...(helpSection?.props || {})} />
          )}
        </div>
      </div>
      <GlobalSearch enable={isLoggedIn} />
      {isInternalUser && isStagingDomain ? (
        <>
          <ClientSelection configOverrides={configOverrides} uiId="p" />
        </>
      ) : null}
      {isInternalUser || isLngDomain ? (
        <LanguageSelection uiId="p" />
      ) : null}
    </AddOnsHandler>
  );
};

const mapStateToProps = (state) => {
  const { userInfo, isInternalUser, preferredTheme } = state.Application;
  const { isPulseUI, doNotRedirectToPulse, helpSection } = state.DomainConfig;

  return {
    userInfo,
    helpSection,
    isInternalUser,
    isPulseUI,
    doNotRedirectToPulse,
    preferredTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirect: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOns);
