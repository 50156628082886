import BaseClient from "../baseClient";

const servicePrefix = "/osPackageSvc/";

class OsPackageSvc extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      ACCESS_DENIED: "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      RECORD_DUPLICATION:
        "It seems this entry already exists. Please update your name to make it unique.",
      INVALID_PARAMETER:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      MAX_PACKAGE_USED_EXHAUSTED_FOR_BRAND: `The maximum package quantity usage for {{brandName}} has been reached.`,
      MAX_PACKAGE_USED_EXHAUSTED: "The maximum package usage has been exhausted.",
      INVALID_PACKAGE_QUANTITY_LIMIT_FOR_BRAND: `Only {{availableBookingPerBrand}} of {{packageName}} left for {{brandName}}.`,
      INVALID_PACKAGE_QUANTITY_LIMIT: `Only {{availableBookingOverall}} remaining for {{packageName}}.`,
    };
  }

  static postCampaignTemplates(payload) {
    return this.apiCall({
      url: `${servicePrefix}campaignTemplates`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static getCampaignTemplates(payload) {
    return this.apiCall({
      url: `${servicePrefix}campaignTemplates`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static getTacticsTargeting(payload) {
    return this.apiCall({
      url: `${servicePrefix}tving/data/publisherTargets`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static getPublisherCustomTargetingKeyValues(payload) {
    return this.apiCall({
      url: `${servicePrefix}tving/data/publisherCustomTargetingKeyValues`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static postCampaignTemplatesSatus(payload) {
    return this.apiCall({
      url: `${servicePrefix}campaignTemplates/updateCampaignTemplateStatus`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postProductTemplatesStatus(payload) {
    return this.apiCall({
      url: `${servicePrefix}packages/updatePackageStatus`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postProductTemplates(payload) {
    return this.apiCall({
      url: `${servicePrefix}packages`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static getProductTemplates(payload) {
    return this.apiCall({
      url: `${servicePrefix}packages`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static postBookingStatus(payload) {
    return this.apiCall({
      url: `${servicePrefix}bookings/updateBookingStatus`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postBookingDetails(payload) {
    return this.apiCall({
      url: `${servicePrefix}bookings`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static getBookingDetails(payload) {
    return this.apiCall({
      url: `${servicePrefix}bookings`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static getBrandsDetails(payload) {
    return this.apiCall({
      url: `${servicePrefix}tving/data/brands`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static getIabCategoriesDetails(payload) {
    return this.apiCall({
      url: `${servicePrefix}tving/data/iabCategories`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static getCutomCategoryDetails(payload) {
    return this.apiCall({
      url: `${servicePrefix}tving/data/customCategories`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static postCreativeData(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postActiveCreativeData(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/activeBookingCreative`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postCreativeStatus(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/updateCreativeStatus`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postActiveCrativeStatus(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/updateActiveBookingCreativeStatus`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static getCreativeTranscodingStatus(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/transcodingStatus`,
      method: "GET",
      params: {
        jsonQuery: JSON.stringify(payload),
      },
    });
  }

  static postBulkCreatives(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/bulkCreate`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postBulkCreativesUpdate(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/bulkUpdate`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postActiveBulkCreatives(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/activeBookingBulkCreate`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postActiveBulkCreativesUpdate(payload) {
    return this.apiCall({
      url: `${servicePrefix}creatives/activeBookingBulkUpdate`,
      method: "POST",
      data: JSON.stringify(payload),
    });
  }

  static postCreativeReviewStatus(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creatives/approveVideoCreative`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }
}

export default OsPackageSvc;
