import _uniq from "lodash/uniq";

import { Hades, BaseClient } from "@onlinesales-ai/services-v2";
import { getCookie, customMergeOS, SortAlphabeticalByKey, getUrlParams } from "@onlinesales-ai/util-methods-v2";
import { triggerMonitor } from "@onlinesales-ai/error-catcher-v2";

import { COOKIES } from "@onlinesales-ai/constants-v2";

import {
  parseProcessedEntityId,
  generateProcessedEntityId,
  processEntityMetadataFromAPI,
  entityTypeEnum,
} from "../../src/utilities";

import { setOnboardingDataFetchInProgress } from "../onBoarding";

import Types from "./types";

const ENTITIES_FETCH_LIMIT = 1000;

export const updateEntityInfo = (entityInfoToUpdate) => ({
  type: Types.SET_APP_UPDATE_ENTITY_INFO,
  entityInfoToUpdate,
});

const updateEntitiesFetchState = (entityId, entitiesFetchStateToUpdate) => ({
  type: Types.SET_APP_UPDATE_ENTITY_FETCH_STATE,
  entityId,
  entitiesFetchStateToUpdate,
});

const setEntitiesFetchInProgress = (entitiesFetchInProgress) => ({
  type: Types.SET_APP_ENTITIES_FETCH_IN_PROGRESS,
  entitiesFetchInProgress,
});

export const upsertEntityMetadata = (entityId, entityMetadata) => ({
  type: Types.UPSERT_ENTITY_METADATA,
  entityId,
  entityMetadata,
});

export const updateEntityMetadataList = (entityMetadata) => ({
  type: Types.UPSERT_ENTITY_METADATA_LIST,
  entityMetadata,
});

export const setUserPermissions = (userPermissions) => ({
  type: Types.SET_USER_PERMISSIONS,
  userPermissions,
});

export const setAppLevelDataFetchState = (isLoading) => ({
  type: Types.SET_APP_LEVEL_DATA_FETCH_STATE,
  isLoading,
});

export const changeEntityId = (originalEntityId, entityType) => {
  return async (dispatch, getState) => {
    if (!originalEntityId || !entityType) {
      return;
    }

    const state = getState();

    if (
      originalEntityId !== state.EntityApplication.selectedEntityId ||
      entityType !== state.EntityApplication.selectedEntityType
    ) {
      BaseClient.abortByMethod("GET", { excludeApplication: ["application"] });
      BaseClient.abortByMethod("POST");
    }

    dispatch({
      type: Types.APP_SET_ENTITY_ID,
      entityId: originalEntityId,
      entityType,
    });
  };
};

export const parseEntityData = (entitiesData, parentId) => {
  const entityIds = [];
  let entityInfoToUpdate = {
    clients: {},
    mccs: {},
  };
  let entityMedataToUpdate = {
    clients: {},
    mccs: {},
  };

  entitiesData.forEach((entityObj) => {
    if (!entityObj.entityId || !entityObj.entityType) {
      return;
    }

    if (parentId && !entityObj.parentMCCId) {
      entityObj.parentMCCId = parentId;
    }

    entityObj.entityName = entityObj.entityAlias || entityObj.entityName;
    delete entityObj.entityAlias;

    entityObj.originalEntityId = entityObj.entityId;
    entityObj.entityId = `${entityObj.entityType}_${entityObj.entityId}`;

    if (entityObj.hierarchy?.length > 0) {
      entityObj.hierarchy.forEach((hierarchyObj) => {
        hierarchyObj.entityName = hierarchyObj.entityAlias || hierarchyObj.entityName;
        delete hierarchyObj.entityAlias;
      });

      const {
        entityInfoToUpdate: hierarchyEntityInfo,
        entityMedataToUpdate: hierarchyEntityMetadata,
      } = processHierarchyData(entityObj.hierarchy);

      entityInfoToUpdate = customMergeOS({}, entityInfoToUpdate, hierarchyEntityInfo);
      entityMedataToUpdate = customMergeOS({}, entityMedataToUpdate, hierarchyEntityMetadata);

      delete entityObj.hierarchy;
    }

    entityIds.push(entityObj.entityId);
    if (entityObj.entityType === entityTypeEnum.MCC) {
      entityInfoToUpdate.mccs[entityObj.entityId] = {
        ...(entityInfoToUpdate?.mccs?.[entityObj.entityId] || {}),
        ...entityObj,
      };
      if (entityObj?.metadata) {
        entityMedataToUpdate.mccs[entityObj.entityId] = processEntityMetadataFromAPI(
          entityObj.metadata,
        );
      }
    } else if (entityObj.entityType === entityTypeEnum.CLIENT) {
      entityInfoToUpdate.clients[entityObj.entityId] = {
        ...(entityInfoToUpdate?.clients?.[entityObj.entityId] || {}),
        ...entityObj,
      };
      if (entityObj?.metadata) {
        entityMedataToUpdate.clients[entityObj.entityId] = processEntityMetadataFromAPI(
          entityObj.metadata,
        );
      }
    }

    delete entityObj.entityMetadata;
    delete entityObj.metadata;
  });

  return {
    entityIds,
    entityInfoToUpdate,
    entityMedataToUpdate,
  };
};

const processHierarchyData = (data) => {
  const entitiesData = data || [];

  let currentParentId = null;
  entitiesData.forEach((entityObj) => {
    entityObj.parentMCCId = currentParentId;
    currentParentId = entityObj.entityId;
  });

  return parseEntityData(entitiesData);
};

export const fetchEntityHierarchy = (entityId, entityType, { redirectUrl, routes }) => {
  return async (dispatch, getState) => {
    if (!entityId) {
      return;
    }

    const state = getState();
    const { agencyId } = state.EntityApplication;

    const request = {
      agencyId,
      entityId,
      entityType,
      selectors: ["entityId", "entityType", "hierarchy"],
      offset: 0,
      limit: 500,
    };

    try {
      const response = await Hades.getEntityDataBySelectors(request, "application");
      const { entityIds, entityInfoToUpdate, entityMedataToUpdate } = processHierarchyData(
        response?.entity?.hierarchy,
      );
      dispatch(updateEntityInfo(entityInfoToUpdate));
      dispatch(
        updateEntityMetadataList({
          ...entityMedataToUpdate.clients,
          ...entityMedataToUpdate.mccs,
        }),
      );
      return entityIds;
    } catch (err) {
      if (err.isAborted) {
        return [];
      }

      triggerMonitor("SEV2", "APP_LEVEL_ENTITY_CONFIG_DATA_ERROR", {
        err,
        payload: request,
      });

      const errorMsg = err?.data?.exception?.error?.message;
      if (
        errorMsg === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "Hades Authorization failed" ||
        errorMsg === "User Authentication failed"
      ) {
        dispatch(redirectUrl(routes?.LOGIN?.path));
      }

      return Promise.reject(err);
    }
  };
};

export const fetchEntitiesData = (
  mccId,
  { componentCallbackForEntityIds, limit, offset, redirectUrl, routes } = {},
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { agencyId, marketplaceClientId } = state.DomainConfig;
    const { entitiesFetchState = {}, selectedEntityId } = state.EntityApplication;
    const processedEntityId = generateProcessedEntityId(
      mccId ? entityTypeEnum.MCC : entityTypeEnum.AGENCY,
      mccId || agencyId,
    );
    const parentEntityFetchState = entitiesFetchState?.[processedEntityId] || {};

    if (parentEntityFetchState.isFetchInProgress) {
      return;
    }

    const request = {
      agencyId,
      mccId,
      selectors: [
        "entityId",
        "entityName",
        "entityAlias",
        "entityType",
        "statusType",
        "depth",
        "metadata.businessDefinition",
      ],
      offset: typeof offset === "number" ? offset : parentEntityFetchState.offset || 0,
      limit: limit || ENTITIES_FETCH_LIMIT,
      filters: [{ column: "statusType", operator: "EQUAL_TO", values: ["ACTIVE"] }],
    };

    try {
      if (!componentCallbackForEntityIds) {
        dispatch(
          updateEntitiesFetchState(processedEntityId, {
            ...parentEntityFetchState,
            fetchErrorMsg: null,
            isFetchInProgress: true,
          }),
        );
      }

      const response = await Hades.getEntities(request, "application", {
        abortId: `get_entity_list_${processedEntityId}`,
      });

      const responseData =
        response?.data?.filter?.((entity) => entity.entityId !== marketplaceClientId) || [];

      const entitiesData = SortAlphabeticalByKey(responseData, "entityAlias") || [];
      const { entityIds, entityInfoToUpdate, entityMedataToUpdate } = parseEntityData(
        entitiesData,
        mccId,
      );

      const entitiesFetchStateToUpdate = {
        ...parentEntityFetchState,
        isAllFetched: entitiesData.length !== ENTITIES_FETCH_LIMIT,
        offset:
          (parentEntityFetchState?.offset || 0) +
          Math.min(entitiesData.length, ENTITIES_FETCH_LIMIT),
        children: _uniq([...(parentEntityFetchState?.children || []), ...entityIds]),
        isFetchInProgress: false,
      };

      dispatch(updateEntityInfo(entityInfoToUpdate));
      dispatch(
        updateEntityMetadataList({ ...entityMedataToUpdate.clients, ...entityMedataToUpdate.mccs }),
      );
      if (componentCallbackForEntityIds) {
        componentCallbackForEntityIds(entityIds);
      } else {
        dispatch(updateEntitiesFetchState(processedEntityId, entitiesFetchStateToUpdate));
      }

      // When loading the entities for the agency level, check for selected entity
      if (!mccId && !parentEntityFetchState?.offset && !selectedEntityId) {
        let isEntityIdFound = false;

        try {
          const queryParams = getUrlParams();
          const cookieEntityIdStr =
            queryParams?.entity || getCookie(COOKIES.SELECTED_ENTITY_ID, false);

          const { entityType: cookieEntityType, entityId: cookieEntityId } =
            parseProcessedEntityId(cookieEntityIdStr);
          if (cookieEntityId && cookieEntityType) {
            if (!entityIds.includes(cookieEntityIdStr)) {
              const respEntityIds = await dispatch(
                fetchEntityHierarchy(cookieEntityId, cookieEntityType, { redirectUrl, routes }),
              );
              isEntityIdFound = respEntityIds.length > 0;
            } else {
              isEntityIdFound = true;
            }

            if (isEntityIdFound) {
              dispatch(changeEntityId(cookieEntityId, cookieEntityType));
            }
          }
        } catch (e) {}

        if (!isEntityIdFound && entityIds.length > 0) {
          const { entityType, entityId: originalEntityId } = parseProcessedEntityId(entityIds[0]);
          dispatch(changeEntityId(originalEntityId, entityType));
        }

        if (entityIds.length === 0) {
          dispatch(setOnboardingDataFetchInProgress(false));
          dispatch(setAppLevelDataFetchState(false));
          dispatch(redirectUrl(routes?.ONBOARDING?.path));
        }

        dispatch(setEntitiesFetchInProgress(false));
      }
    } catch (err) {
      if (err.isAborted) {
        return;
      }

      triggerMonitor("SEV2", "APP_LEVEL_ENTITY_DATA_FAILED", {
        err,
        payload: request,
      });
      const errorMsg = err?.errorMsg;
      if (
        errorMsg === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "Hades Authorization failed" ||
        errorMsg === "User Authentication failed"
      ) {
        dispatch(redirectUrl(routes?.LOGIN?.path));
      } else if (!mccId && !parentEntityFetchState?.offset) {
        triggerMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
          calledFrom: "fetchEntitiesData",
        });
        dispatch(redirectUrl(routes?.DOWNTIME?.path));
      } else {
        dispatch(
          updateEntitiesFetchState(processedEntityId, {
            ...parentEntityFetchState,
            fetchErrorMsg: errorMsg,
          }),
        );
      }
      dispatch(setEntitiesFetchInProgress(false));
    }
  };
};

export const onFilterEntityBySearchText = (payload, options) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const state = getState();
      const { marketplaceClientId } = state.DomainConfig;

      try {
        const response = await Hades.getEntitiesBySearch(
          {
            filters: [{ column: "statusType", operator: "EQUAL_TO", values: ["ACTIVE"] }],
            ...payload,
          },
          "application",
          options,
        );

        const entitiesData =
            response?.data?.filter?.((entity) => entity.entityId !== marketplaceClientId) || [];

        entitiesData.forEach((entityObj) => {
          if (entityObj?.hierarchy?.length >= 2) {
            const parentEntityInfo = entityObj.hierarchy[entityObj.hierarchy.length - 2];
            entityObj.parentMCCId =
              parentEntityInfo.entityType === entityTypeEnum.MCC ? parentEntityInfo.entityId : null;
          }
          entityObj.sortingDataKey = entityObj?.hierarchy?.map((i) => i?.entityAlias).join(" > ");
        });

        const sortedEntitiesData = SortAlphabeticalByKey(entitiesData, "sortingDataKey");

        const { entityIds, entityInfoToUpdate, entityMedataToUpdate } =
          parseEntityData(sortedEntitiesData);
        dispatch(updateEntityInfo(entityInfoToUpdate));
        dispatch(
          updateEntityMetadataList({
            ...entityMedataToUpdate.clients,
            ...entityMedataToUpdate.mccs,
          }),
        );

        resolve(entityIds);
      } catch (e) {
        // reject(e);
        triggerMonitor("SEV2", "APP_LEVEL_ENTITY_DATA_FAILED", {
          err: e,
          payload,
        });
        if (!e.isAborted) {
          resolve([]);
        }
      }
    });
  };
};

export const fetchUserAgencyPermissions = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { agencyId } = state.DomainConfig;

    const payload = {
      entityId: agencyId,
      entityType: "AGENCY",
      selectors: ["permissions"],
    };

    const response = await Hades.getEntityDataBySelectors(payload, "application");
    dispatch(setUserPermissions(response?.entity?.permissions));

    return response?.entity;
  };
};
