import React from "react";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { withTranslation } from "react-i18next";
import { subMinutes, addMinutes, differenceInDays } from "date-fns";

import { format, formattedDate, getClientTimezoneText } from "@onlinesales-ai/util-methods-v2";
import { DateRangePicker, UTCDateRangePicker } from "@onlinesales-ai/datepicker-v2";

import FormWrapper from "../../FormWrapper";

class DateRange extends React.Component {
  constructor(props) {
    super(props);

    const { dataKeyEndDate, dataKeyStartDate } = props;
    const endDate = this.getValue(props, dataKeyEndDate);
    const startDate = this.getValue(props, dataKeyStartDate);

    this.validate({
      endDate,
      startDate,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataKeyEndDate, dataKeyStartDate } = nextProps || this.props;
    const endDate = this.getValue(nextProps, dataKeyEndDate);
    const startDate = this.getValue(nextProps, dataKeyStartDate);

    if (
      !_isEqual(endDate, this.getValue(null, dataKeyEndDate)) ||
      !_isEqual(startDate, this.getValue(null, dataKeyStartDate))
    ) {
      this.validate({ startDate, endDate });
    }
  }

  getValue = (props, datakey) => {
    const { formValues, value, getValueInUTC } = props || this.props;
    let result = value || _get(formValues, datakey);
    if (getValueInUTC && result) {
      result = addMinutes(new Date(result), new Date(result).getTimezoneOffset());
    }
    return result;
  };

  validate = (value) => {
    const { onError, dataKey, validations = [], title, maxSelectionDays } = this.props;
    let errorMsg = null;

    for (let i = 0; i < validations.length; i++) {
      if (!validations[i].type || !validations[i].msg) {
        continue;
      }

      switch (validations[i].type) {
        case "nonEmpty":
          {
            if (!value?.startDate || !value?.endDate) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        case "maxValue":
          {
            if (differenceInDays(value?.endDate, value?.startDate) + 1 > maxSelectionDays) {
              errorMsg = validations[i].msg;
            }
          }
          break;
        default:
          break;
      }

      if (errorMsg) {
        errorMsg = errorMsg.replace("__FIELD_TITLE__", title.toLowerCase());
        break;
      }
    }

    onError({ [dataKey]: errorMsg });
  };

  onChangeDate = (value) => {
    const { onChange, dataKeyStartDate, dataKeyEndDate, getValueInUTC } = this.props;
    if (getValueInUTC) {
      const startDateToUse = subMinutes(value.startDate, value.startDate.getTimezoneOffset());
      const endDateToUse = subMinutes(value.endDate, value.endDate.getTimezoneOffset());
      onChange({
        [dataKeyStartDate]: startDateToUse.valueOf(),
        [dataKeyEndDate]: endDateToUse.valueOf(),
      });
    } else {
      onChange({
        [dataKeyStartDate]: value.startDate.valueOf ? value.startDate.valueOf() : value.startDate,
        [dataKeyEndDate]: value.endDate.valueOf ? value.endDate.valueOf() : value.endDate,
      });
    }
  };

  render() {
    const {
      // FormWrapper props
      labelColumns,
      formGroupClassName,
      guidText,
      guidElement,
      apiError,
      warning,
      title,
      titleTooltip,
      titleGuidText,

      // tag input props
      dataKeyStartDate,
      dataKeyEndDate,
      isEditable,
      showErrors,
      formErrors,
      disabled,
      dateFormat,
      minDate,
      showPresets,
      presets,
      dataKey,
      datePickerProps,
      dateRangeProps,
      isRequired,
      renderInPortal,
      overlayProps,
      popoverPosition,
      useOverlay,
      placeholder,
      startAndEndOfDay,
      useUTCDateRange,
      showTimezoneGuide,
      t,
      labelDefinationKey,
    } = this.props;

    const startDate = this.getValue(this.props, dataKeyStartDate);
    const endDate = this.getValue(this.props, dataKeyEndDate);

    let min = minDate;

    if (minDate === "now") {
      min = Date.now();
    }

    const Component = useUTCDateRange ? UTCDateRangePicker : DateRangePicker;

    let guidElementToUse = guidElement;

    if (!guidElementToUse && showTimezoneGuide) {
      guidElementToUse = (
        `${t("Timezone")}: ${getClientTimezoneText()}`
      );
    }

    return (
      <FormWrapper
        labelColumns={labelColumns}
        formGroupClassName={formGroupClassName}
        hasError={showErrors && formErrors[dataKey]}
        error={formErrors[dataKey]}
        apiError={apiError}
        guidElement={guidElementToUse}
        guidText={guidText}
        warning={warning}
        title={title}
        titleTooltip={titleTooltip}
        titleGuidText={titleGuidText}
        isEditable={isEditable}
        isRequired={isRequired}
        labelDefinationKey={labelDefinationKey}
      >
        {isEditable ? (
          <Component
            startDate={startDate}
            endDate={endDate}
            onChange={this.onChangeDate}
            disabled={disabled}
            dateFormat={dateFormat}
            presets={showPresets ? presets : []}
            startAndEndOfDay={startAndEndOfDay}
            datePickerProps={{
              minDate: min,
              ...datePickerProps,
            }}
            enableborderbottom
            renderInPortal={renderInPortal}
            popoverPosition={popoverPosition}
            useOverlay={useOverlay}
            overlayProps={overlayProps}
            placeholder={placeholder}
            {...dateRangeProps}
          />
        ) : (
          <>
            {startDate && endDate ? (
              <div className="non-edit-mode-text">
                {`${formattedDate(startDate, "date", { useFormat: true })} to ${formattedDate(
                  endDate,
                  "date",
                  { useFormat: true },
                )}`}
              </div>
            ) : null}
          </>
        )}
      </FormWrapper>
    );
  }
}

DateRange.defaultProps = {
  dataKeyStartDate: null,
  dataKeyEndDate: null,
  dataKey: null,
  title: "",
  labelColumns: 3,
  datePickerProps: {},
  dateRangeProps: {},
  showPresets: true,
  formGroupClassName: "form-component-daterange",
  validations: [
    {
      type: "nonEmpty",
      msg: "Please enter __FIELD_TITLE__",
    },
  ],
};

export default withTranslation()(DateRange);
