import BaseClient from "../baseClient";

const servicePrefix = "/brandAdsUIService/";
const commentServicePrefix = "/commentsService/";
class BrandAdsService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      DUPLICATE_MARKETING_CAMPAIGN_NAME:
        "Campaign with same name already exist. Please use different name.",
      UNAUTHORIZED:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      INVALID_PARAMETERS_ERROR:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      SCHEDULE_NOT_FOUND: "Schedule not found, please retry",
      INVENTORY_NOT_FOUND: "Inventories not found, please retry",
      POST_SCHEDULES_FAILED: "Schedule selection could not be saved",
      POST_CREATIVES_FAILED: "Creative Configurations could not be saved",
      POST_ADS_FAILED: "Your campaign could not be submitted for approval, please retry",
      GET_WALLET_AMOUNT_ERROR: "Wallet amount can’t be fetched, please retry",
      CHARGE_WALLET_ERROR: "Your wallet could not be charged, please retry",
      PAYMENT_API_ERROR_CODES: "Payment could not be triggered, please retry",
      INVALID_SCHEDULES: "Schedule invalid",
      AMOUNT_VERIFICATION_FAILED: "Payment failed, please retry",
      INSUFFICIENT_AMOUNT: "Sufficient balance not found, please add balance and retry",
      SAVE_INVENTORY_FAILED: "Inventory not saved, please retry",
      CAMPAIGNS_NOT_FOUND: "Campaigns not found",
      ACCOUNT_NOT_FOUND: "Looks like we are facing some internal issue. Please, retry after sometime.",
      POST_CAMPAIGNS_FAILED: "Campaign failed to save, please retry",
      // MARKETING_CAMPAIGNS_NOT_FOUND: "Marketing campaigns not found",
      // POST_MARKETING_CAMPAIGN_FAILED: "Marketing campaign failed to save, please retry",
      TAGS_POST_FAILED: "Tags could not be saved, please retry",
      CAMPAIGN_IDS_NOT_PRESENT_IN_TAGS: "Campaign IDs not found in tags",
      INVALID_DATE: "Please re-configure the Smart Inventory Recommendation date range",
      DAILY_BUDGET_MORE_THAN_SPEND_CAP_NOT_ALLOWED: "Daily budget cannot be more than Total Budget",
      DUPLICATE_INVENTORY_AD_UNIT_ERROR: "Duplicate API Identifier, please update and retry",
    };
  }

  static fetchCampaigns(config = {}, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchCampaigns`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
    );
  }

  static fetchAdminCampaigns(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}adminV2/fetch`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static postCampaignInfo(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}createCampaigns`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchCreativesForInventory(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchCreativesForInventoryV2`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static fetchCreativesForInventoryV2(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchCreativesForInventoryV2`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchAdminCreatives(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}admin/creatives`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static postBrandAdsCreatives(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}creatives/saveV2`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static saveTargetSettings(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}targetingSettings/save`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCreativesLibrary(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creatives/creativeLibrary`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchProductCountForMerchandise(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchProductCountForMerchandise`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCategories(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchCategories`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchInventories(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchInventoryDetails`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static fetchSlots(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchAvailableDatesForInventory`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static saveInventory(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}inventoryConfiguration/save`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postPayment(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}payment`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchCampaignComments(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${commentServicePrefix}comments`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static fetchEntityComments(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${commentServicePrefix}comments/entity`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static postCampaignComment(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${commentServicePrefix}comments`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postEntityComment(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${commentServicePrefix}comments/entity`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchThemes(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchThemes`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchThemeCategories(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchCategoriesByThemes`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchInventoryRecommendation(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchInventoryRecommendations`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchPageTypes(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchPageTypesByCategories`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
      options,
    );
  }

  static postTargetingSettings(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}targetingSettings/save`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchMarketplaceWrapper(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchMarketplaceWrapper`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postMarketplaceWrapper(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}fetchMarketplaceWrapper/postWrapperData`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postPageType(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}/pageType`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static postAdFormats(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}creativeTemplates`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static postInventoryAdUnits(payload, application, options) {
    return this.apiCall(
      {
        url: `${options?.servicePrefix || ""}${servicePrefix}osInventoryAdUnits`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchAdPages(payload, application) { 
    return this.apiCall(
      {
        url: `${servicePrefix}fetchPageTypesAdmin`,
        // url: `https://run.mocky.io/v3/d9d9ab78-834c-4c40-8ed4-673b6bc6a9d7`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchAdFormats(payload, application) { 
    return this.apiCall(
      {
        url: `${servicePrefix}creativeTemplates`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchAdDimensions(payload, application) { 
    return this.apiCall(
      {
        url: `${servicePrefix}dimensions`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchCreativeTypes(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}creativeTypes`,
        // url: `https://run.mocky.io/v3/b8640a24-e638-4448-a2cc-2d4add955b51`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static fetchOsInventoryAdUnits(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}osInventoryAdUnits`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postInventory(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}osInventoryAdUnits`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static fetchInventoryImpEstimations(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchInventoryImpEstimations`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(payload) },
      },
      application,
    );
  }

  static postCampaignReviewer(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}adminV2/assignCampaignReviewer`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }
}

export default BrandAdsService;
