import React from "react";

const AppLoader = () => {
  const { hostname } = window.location || {};
  let url = "https://osads.gumlet.io/image/upload/v1580290660/product/spinner_loader.gif";

  if (hostname.match("konga.onlinesales.ai") || hostname.match("konga-ops.onlinesales.ai")) {
    url = "https://res.cloudinary.com/onlinesales/image/upload/w_60/logos/konga_loader.gif";
  } else if (
    hostname.match("oms-adops.tving.com") ||
    hostname.match("oms-dev-adops.tving.com") ||
    hostname.match("tving-prod.onlinesales.ai") ||
    hostname.match("tving-prod-pulse.onlinesales.ai") ||
    hostname.match("tving-prod-staging.onlinesales.ai") ||
    hostname.match("tving-prod-staging-pulse.onlinesales.ai") ||
    hostname.match("tving-qa.onlinesales.ai") ||
    hostname.match("tving-qa-pulse.onlinesales.ai") ||
    hostname.match("tving-dev.onlinesales.ai") ||
    hostname.match("tving-dev-pulse.onlinesales.ai") ||
    hostname.match("dunnhumby-sandbox.onlinesales.ai") ||
    hostname.match("dunnhumby-sandbox-pulse.onlinesales.ai")
  ) {
    url = "https://osads.gumlet.io/image/upload/v1580290660/product/spinner_loader.gif";
  } else if (hostname.includes("pulse") || hostname.includes("ops")) {
    url =
      "https://res.cloudinary.com/onlinesales/image/upload/v1707222333/product/osmos/spinner_loader.gif";
  } else if (
    hostname.match("blibli.onlinesales.ai") ||
    hostname.match("blibli-ops.onlinesales.ai") ||
    hostname.match("blibli-v2.onlinesales.ai") ||
    hostname.match("blibli-staging.onlinesales.ai") ||
    hostname.match("paytm-sellers.onlinesales.ai") ||
    hostname.match("paytm-staging.onlinesales.ai") ||
    hostname.match("paytm-sellers-ops.onlinesales.ai") ||
    hostname.match("paytm-brands.onlinesales.ai") ||
    hostname.match("paytm-brands-ops.onlinesales.ai") ||
    hostname.match("paytm-brands-staging.onlinesales.ai") ||
    hostname.match("staging-apps.onlinesales.ai") ||
    hostname.match("staging-v1-admin.onlinesales.ai") ||
    hostname.match("thanachart.onlinesales.ai") ||
    hostname.match("maruti.onlinesales.ai") ||
    hostname.match("maruti-ops.onlinesales.ai") ||
    hostname.match("driveconnectplatform.onlinesales.ai") ||
    hostname.match("bukukas-staging.onlinesales.ai") ||
    hostname.match("bukukas.onlinesales.ai") ||
    hostname.match("bankopen-staging.onlinesales.ai") ||
    hostname.match("bankopen.onlinesales.ai") ||
    hostname.match("flutterwave.onlinesales.ai") ||
    hostname.match("flutterwave-staging.onlinesales.ai") ||
    hostname.match("shopmatic.onlinesales.ai") ||
    hostname.match("shopmatic-staging.onlinesales.ai") ||
    hostname.match("bulevip-staging.onlinesales.ai") ||
    hostname.match("bulevip-ops.onlinesales.ai") ||
    hostname.match("bulevip.onlinesales.ai") ||
    hostname.match("bulevip-brandads.onlinesales.ai") ||
    hostname.match("bulevip-staging-brandads.onlinesales.ai") ||
    hostname.match("starquik.onlinesales.ai") ||
    hostname.match("starquik-staging.onlinesales.ai") ||
    hostname.match("1mg.onlinesales.ai") ||
    hostname.match("1mg-staging.onlinesales.ai") ||
    hostname.match("1mg-brandads.onlinesales.ai") ||
    hostname.match("1mg-staging-brandads.onlinesales.ai") ||
    hostname.match("kornershoppe.onlinesales.ai") ||
    hostname.match("kornershoppe-prod.onlinesales.ai") ||
    hostname.match("kornershoppe-admin.onlinesales.ai") ||
    hostname.match("kornershoppe-prod-admin.onlinesales.ai") ||
    hostname.match("inmarads-demo.onlinesales.ai") ||
    hostname.match("inmarads-rmn.onlinesales.ai") ||
    hostname.match("inmarads-retailer.onlinesales.ai") ||
    hostname.match("inmarads-demo-admin.onlinesales.ai") ||
    hostname.match("inmarads-rmn-admin.onlinesales.ai") ||
    hostname.match("inmarads-retailer-admin.onlinesales.ai") ||
    hostname.match("snapdeal.onlinesales.ai") ||
    hostname.match("snapdeal-ops.onlinesales.ai") ||
    hostname.match("snapdeal-staging.onlinesales.ai") ||
    hostname.match("snapdeal-staging-ops.onlinesales.ai") ||
    hostname.match("tops.retailcloud.inmar.com") ||
    hostname.match("demo-retailcloud.inmar.com") ||
    hostname.match("picknpay.onlinesales.ai") ||
    hostname.match("picknpay-staging.onlinesales.ai") ||
    hostname.match("picknpay-ops.onlinesales.ai") ||
    hostname.match("picknpay-staging-ops.onlinesales.ai") ||
    hostname.match("retailmedia-qa.pnpiq.co.za") ||
    hostname.match("retailmedia.pnpiq.co.za") ||
    hostname.match("senheng.onlinesales.ai") ||
    hostname.match("senheng-staging.onlinesales.ai") ||
    hostname.match("senheng-ops.onlinesales.ai") ||
    hostname.match("senheng-staging-ops.onlinesales.ai") ||
    hostname.match("oms.tving.com") ||
    hostname.match("oms-dev.tving.com") ||
    hostname.match("monetize-staging-4.onlinesales.ai")
  ) {
    url = "https://osads.gumlet.io/image/upload/v1580290660/product/spinner_loader.gif";
  } else if (
    hostname.match("hotstar-sd.onlinesales.ai") ||
    hostname.match("hotstar-sd-prod.onlinesales.ai") ||
    hostname.match("hotstar-sd-admin.onlinesales.ai") ||
    hostname.match("hotstar-sd-prod-admin.onlinesales.ai") ||
    hostname.match("reports-adsmanager.hotstar.com") ||
    hostname.match("hotstar-prod-admin.onlinesales.ai") ||
    hostname.match("hotstar-prod.onlinesales.ai") ||
    hostname.match("adsmanager.hotstar.com") ||
    hostname.match("hotstar.onlinesales.ai") ||
    hostname.match("hotstar-admin.onlinesales.ai") ||
    hostname.match("hotstar-staging.onlinesales.ai") ||
    hostname.match("hotstar-staging-admin.onlinesales.ai") ||
    hostname.match("appslocal-dev.onlinesales.ai")
  ) {
    url = "https://osads.gumlet.io/image/upload/v1687351534/product/hotstar_loader.gif";
  }

  return (
    <div className="loader-wrapper white-loader">
      <img alt="logo" className="loader-img" src={url} style={{ width: 60 }} />
    </div>
  );
};

export default AppLoader;
