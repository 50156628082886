import _omit from "lodash/omit";
import _groupBy from "lodash/groupBy";
import _sortBy from "lodash/sortBy";
import _keyBy from "lodash/keyBy";
import _pick from "lodash/pick";

import { customMergeOS } from "@onlinesales-ai/util-methods-v2";

import Types from "./types";
import AppActionTypes from "../application/types";

const initialState = {
  isFetchInProgress: false,
  isFetchSuccessful: false,
  isFirstCallInProgress: true,
  isFirstCallSuccessful: false,
  fetchErrorMsg: null,
  goalsOffset: 0,
  sortedGoalIds: [],
  inventoryData: {},
  goalDetails: {},
  inventorySlotStatus: {},
  pageTypes: {},
  campaignListForNotificationsOnly: {},
};

const removeOldCreativesData = (datas) => {
  const modifiedGoals = {};
  Object.keys(datas || []).forEach((goalId) => {
    const goal = datas[goalId];

    if (goal?.data?.id) {
      const gropupedCreatives = _groupBy(goal.data?.creatives, "inventoryAdUnitIds") || {};
      const creativesToSave = (Object.values(gropupedCreatives) || []).map((creativeList) => {
        const creativeL = _sortBy(creativeList, ["adCreativeId"]);
        return creativeL[creativeL.length - 1] || {};
      });
      modifiedGoals[goalId] = {
        ...goal,
        data: {
          ...goal.data,
          creatives: creativesToSave,
        },
      };
    } else {
      modifiedGoals[goalId] = goal;
    }
  });
  return modifiedGoals;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.APP_CLIENT_CHANGE:
      {
        state = {
          ...initialState,
        };
      }
      break;

    case Types.SET_BRAND_ADS_INVENTORY_DATA:
      {
        state = {
          ...state,
          inventoryData: action.data || {},
        };
      }
      break;

    case Types.SET_BRAND_ADS_PAGE_TYPES:
      {
        state = {
          ...state,
          pageTypes: action.pageTypes || {},
        };
      }
      break;

    case Types.SET_BRAND_ADS_INVENTORY_SLOTS_DATA:
      {
        state = {
          ...state,
          inventoryData: {
            ...state.inventoryData,
            [action.inventoryId]: {
              ...(state.inventoryData?.[action.inventoryId] || {}),
              slotsData: action.data,
            },
          },
        };
      }
      break;

    case Types.MERGE_ALL_GOALS_DATA_INVENTORY:
      {
        const goalDetails = customMergeOS(action.goalsData, state.goalDetails);
        state = {
          ...state,
          goalDetails,
        };
      }
      break;

    case Types.SET_BRAND_ADS_GOALS:
      {
        const goalDetails = customMergeOS(state.goalDetails, action.goals);
        state = {
          ...state,
          // goalDetails: removeOldCreativesData(action.goals),
          goalDetails,
        };
      }
      break;

    case Types.UPDATE_BRAND_ADS_GOAL_DATA:
      {
        const inventoryDetails = [];
        action?.data?.data?.inventoryDetails?.forEach((inventory) => {
          const withDataInventory =
            (state.goalDetails[action.id]?.data?.inventoryDetails || []).find(
              (o) => o?.inventoryAdUnitId === inventory?.inventoryAdUnitId,
            ) || {};
          let array = { ...withDataInventory, ...inventory };
          if (inventory?.inventoryAdUnitId) {
            inventoryDetails.push(array);
          }
        });
        const goalDetails = {
          ...state.goalDetails,
          [action.id]: {
            ...state.goalDetails[action.id],
            ...action.data,
            data: {
              ...(state.goalDetails[action.id]?.data || {}),
              ...action.data?.data,
              ...(inventoryDetails?.length && { inventoryDetails }),
            },
          },
        };
        state = {
          ...state,
          // goalDetails: removeOldCreativesData(goalDetails),
          goalDetails,
          campaignListForNotificationsOnly: {
            ...state.campaignListForNotificationsOnly,
            [action.id]: {
              ...(state.campaignListForNotificationsOnly?.[action.id] || {}),
              data: {
                ...(state.campaignListForNotificationsOnly?.[action.id]?.data || {}),
                ..._pick(action?.data?.data, ["id", "name", "alias", "status", "budget", "effectiveStatus"]),
              },
            },
          },
        };
      }
      break;

    case Types.SET_SELECTED_NETWORK_LIST:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              data: {
                ...(state.goalDetails[action.goalId]?.data || {}),
                networkDetails: action?.list,
              },
            },
          },
        };
      }
      break;

    case Types.UPDATE_BRAND_ADS_GOAL_CREATIVE_DATA:
      {
        const newCreatives = [];
        [...(state.goalDetails[action.goalId]?.data?.creatives || []), action.data || {}].forEach(
          (creative) => {
            if (action.adCreativeId === creative.adCreativeId) {
              if (action.data?.status !== "DELETED") {
                newCreatives.push(action.data);
              }
            } else {
              newCreatives.push(creative);
            }
          },
        );
        const goalDetails = {
          ...state.goalDetails,
          [action.goalId]: {
            ...state.goalDetails[action.goalId],
            data: {
              ...(state.goalDetails[action.goalId]?.data || {}),
              creatives: newCreatives,
            },
          },
        };
        state = {
          ...state,
          goalDetails,
        };
      }
      break;
    case Types.UPDATE_BRAND_ADS_GOAL_MERCHENDISE_DATA:
      {
        const merchDetails = state.goalDetails?.[action.id]?.merchandiseDetails || [];
        const bySettingsNew = _groupBy(action.datas, "settingId");
        const bySettingsOld = _groupBy(merchDetails, "settingId");
        const newSettingsById = customMergeOS(bySettingsOld, bySettingsNew);
        const updatedDetails = Object.values(newSettingsById).map((values) => {
          return values[0];
        });
        const goalDetails = {
          ...state.goalDetails,
          [action.id]: {
            ...(state.goalDetails[action.id] || {}),
            data: {
              ...(state.goalDetails[action.id]?.data || {}),
              merchandiseDetails: [...updatedDetails],
            },
          },
        };
        state = {
          ...state,
          // goalDetails: removeOldCreativesData(goalDetails),
          goalDetails,
        };
      }
      break;

    case Types.UPDATE_BRAND_ADS_GOAL_CAMPAIGN_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.id]: {
              ...state.goalDetails[action.id],
              data: {
                ...(state.goalDetails?.[action.id]?.data || {}),
                ...action.data,
              },
            },
          },
        };
      }
      break;

    case Types.UPDATE_CAMPAIGN_STATUS:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.id]: {
              ...state.goalDetails[action.id],
              data: {
                ...(state.goalDetails?.[action.id]?.data || {}),
                status: action.status,
              },
            },
          },
        };
      }
      break;

    case Types.SET_BRAND_ADS_INVENTORY_SLOT_STATUS:
      {
        state = {
          ...state,
          inventorySlotStatus: action.data,
        };
      }
      break;

    case Types.DELETE_BRAND_ADS_SLOT_STATUS:
      {
        state = {
          ...state,
          inventorySlotStatus: {
            ..._omit(state.inventorySlotStatus, action.ids),
          },
        };
      }
      break;

    case Types.UPDATE_BRAND_ADS_SCHEDULE_STATUS:
      {
        const currentGoalData = state.goalDetails[action.goalId]?.data || {};

        if (currentGoalData?.inventoryDetails?.length) {
          action.data.forEach((inventory) => {
            const goalInventory = currentGoalData.inventoryDetails.find(
              (i) => i.inventoryAdUnitId === inventory.inventoryAdUnitId,
            );
            if (goalInventory) {
              inventory.schedules.forEach((schedule) => {
                const update = goalInventory.schedules.find(
                  (s) => s.scheduleId === schedule.scheduleId,
                );

                if (update) {
                  update.state = action.newState || schedule.newState;
                }
              });
            }
          });
        }

        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...state.goalDetails[action.goalId],
              data: { ...currentGoalData },
            },
          },
        };
      }
      break;
    case Types.SET_BRAND_ADS_KEYWORD_BIDDING_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              keywordBiddingData: {
                ...(state.goalDetails?.[action.goalId]?.keywordBiddingData || {}),
                [action.keywordType]: _keyBy(action.data?.keywords || [], "id"),
              },
            },
          },
        };
      }
      break;
    case Types.SET_BRAND_ADS_KEYWORD_BIDDING_FETCH_STATUS:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              isKeywordBiddingFetchInProgress: action.isFetchInProgress,
            },
          },
        };
      }
      break;
    case Types.UPDATE_BRAND_ADS_KEYWORD_BIDDING_DATA:
      {
        const dataToUpdate = {
          [action.keywordType]: {
            ...(state.goalDetails?.[action.goalId]?.keywordBiddingData?.[action.keywordType] || {}),
            ..._keyBy(action.data?.keywords || [], "id"),
          },
        };
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              keywordBiddingData: {
                ...(state.goalDetails?.[action.goalId]?.keywordBiddingData || {}),
                ...dataToUpdate,
              },
            },
          },
        };
      }
      break;

    case Types.SET_GOALS_LIST_FETCH_IN_PROGRESS:
      state = {
        ...state,
        isFetchInProgress: action.isLoading,
      };
      break;

    case Types.SET_GOALS_FETCH_SUCCESSFUL:
      state = {
        ...state,
        isFetchSuccessful: action.isSuccessful,
      };
      break;

    case Types.SET_IS_FIRST_CALL_IN_PROGRESS_BRAND_ADS:
      state = {
        ...state,
        isFirstCallInProgress: action.isFirstCallInProgress,
      };
      break;
    case Types.SET_IS_FIRST_CALL_SUCCESSFUL_BRAND_ADS:
      state = {
        ...state,
        isFirstCallSuccessful: action.isFirstCallSuccessful,
      };
      break;

    case Types.UPDATE_GOALS_OFFSET_BRAND_ADS:
      {
        state = {
          ...state,
          goalsOffset: action.offset,
        };
      }
      break;

    case Types.SET_GOALS_FETCH_ERROR_MSG:
      state = {
        ...state,
        fetchErrorMsg: action.errorMsg,
      };
      break;

    case Types.MAKE_GOAL_EMPTY:
      state = {
        ...state,
        goalDetails: {},
      };
      break;
    case Types.MAKE_GOALS_SORTED_EMPTY:
      state = {
        ...state,
        sortedGoalIds: [],
      };
      break;
    case Types.SET_GOALS_SORTED_IDS:
      state = {
        ...state,
        sortedGoalIds: [...state?.sortedGoalIds, ...action?.sortedGoalIds],
      };
      break;

    case Types.SET_CAMPAIGN_DEBUGGER_DATA:
      {
        state = {
          ...state,
          goalDetails: {
            ...state.goalDetails,
            [action.goalId]: {
              ...(state.goalDetails?.[action.goalId] || {}),
              data: {
                ...(state.goalDetails[action.goalId]?.data || {}),
                traceDetails: action.traceDetails,
              },
            },
          },
        };
      }
      break;
    case Types.AUCTION_GOAL_LIST_NOTIFICATION:
      {
        state = {
          ...state,
          campaignListForNotificationsOnly: action?.campaigns || {},
        };
      }
      break;
    default:
      break;
  }
  return state;
};

export default {
  BrandAds: reducer,
};
