export default {
  "/financeService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/ticketManagementService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/inceptionService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/multiVerseService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/api/user-setting-mappings": {
    target: "https://osapi.onlinesales.ai",
  },
  "/reviewService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/kamService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/adPreviewSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/starkService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/launcherStrategySvcV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/changeHistoryService/": {
    target: "https://osapi.onlinesales.ai",
  },
  "/adwordsAccessorService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/osBulkActionsService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/campaignLauncher": {
    target: "https://osapi.onlinesales.ai",
  },
  "/changeLogDbService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/changeLogDbServiceV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/clientInputService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/configurationDbService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/creativeUploadService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/geoTargetingService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/goalValidatorServiceV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/hadesV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/intercom_events": {
    target: "https://osapi.onlinesales.ai",
  },
  "/intercomEvents": {
    target: "https://osapi.onlinesales.ai",
  },
  "/irisExecService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/kibanaUI": {
    target: "https://osapi.onlinesales.ai",
  },
  "/marketingCampaignsDbSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/marketingCampaignServiceV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/ninkasiService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/notificationsServiceV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/notificationsService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/onboardingService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/osBillingService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/osBillingServiceV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/propertySettingsSvcV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/reportingSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/merchandiseFeedService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/reportingUISvc/mediaLibrary": {
    target: "https://osapi.onlinesales.ai",
  },
  "/reportingUISvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/shopsUIService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/sokratiLiveService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/stripeAccessorService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/suggestionDbSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/tagsRetrofittingSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/ttyaService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/uploaderSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/watchlistService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/osAdsService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/sokratiShopsService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/onBoardingSvcV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/merchandiseDbSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/osGravitiService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/marketplaceService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/marketplace": {
    target: "https://osapi.onlinesales.ai",
  },
  "/leadManagementService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/neonService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/videoXService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/eventManagerServiceV2": {
    target: "https://osapi.onlinesales.ai",
  },
  "/iftttRulesService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/brandAdsUIService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/commentsService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/craftService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/masterGoalService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/keywordTargetingService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/trackingEventsUIService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/osPackageSvc": {
    target: "https://osapi.onlinesales.ai",
  },
  "/changeHistoryService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/nexusService": {
    target: "https://osapi.onlinesales.ai",
  },
  "/kuberService": {
    "target": "https://osapi.onlinesales.ai",
  },
};
