import React, { useMemo, useState, useRef, useEffect } from "react";
import { Trans } from "react-i18next";
import _cloneDeep from "lodash/cloneDeep";

import { Overlay, Popover } from "react-bootstrap";

import { Input } from "@onlinesales-ai/input-v2";
import CollapsibleCard from "@onlinesales-ai/collapsible-card-v2";

import "./index.less";
import WithTooltip from "@onlinesales-ai/tooltip-v2";

const PopoverMenu = ({
  modalTitle,
  menuOptions,
  children,
  onMenuSelect,
  isOpen,
  placement,
  onToggle,
  popoverId,
  popoverProps,
  defaultPopoverContent,
  enableSearch,
  popoverMenuMediaWrapperClass,
  ...props
}) => {
  const [isMediaPopoverMenuOpen, setIsMediaPopoverMenuOpen] = useState(false);
  const targetRef = useRef({});
  const containerRef = useRef({});
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchTerm("");
  }, [isMediaPopoverMenuOpen]);

  const togglePopoverMenu = (isShow) => {
    if (onToggle) {
      onToggle(isShow);
    } else {
      setIsMediaPopoverMenuOpen(isShow);
    }
  };

  const onTogglePopover = () => {
    togglePopoverMenu(!isMediaPopoverMenuOpen);
  };

  const onClosePopover = () => {
    togglePopoverMenu(false);
  };

  const renderMenuItemContent = (option) => {
    return (
      <WithTooltip title={option.tooltip} overlayProps={{ delay: { show: 350 } }}>
        <div
          className={`popover-menu-item ${option.disabled && "disabled"}`}
          onClick={(e) => {
            if (!option.disabled) {
              e.stopPropagation();
              onClosePopover();
              onMenuSelect(option.value);
            }
          }}
        >
          {option.iconClass && (
            <div className="menu-item-left">
              <span className={`icon ${option.iconClass}`} />
            </div>
          )}
          <div className="menu-item-right">
            {option.label && (
              <div className="menu-item-title">
                <Trans>{option.label}</Trans>
              </div>
            )}
            {option.description && (
              <div className="menu-item-description">
                <Trans>{option.description}</Trans>
              </div>
            )}
          </div>
        </div>
      </WithTooltip>
    );
  };

  const searchedOptions = useMemo(() => {
    let newMenuOptions = _cloneDeep(menuOptions);
    newMenuOptions = newMenuOptions.filter((option) => {
      let result = false;
      result = option.label?.toLowerCase().includes(searchTerm?.toLowerCase());
      if (!result && option.options?.length) {
        option.options = (option.options || []).filter(({ label }) => {
          return label?.toLowerCase().includes(searchTerm?.toLowerCase());
        });
        result = !!option.options?.length;
      }
      return result;
    });
    return newMenuOptions;
  }, [searchTerm, menuOptions]);

  const renderSubMenu = (option) => {
    return (
      <CollapsibleCard
        cardHeader={
          <div className="card-title">
            {option.label}
            <span
              className={`icon icon-caret-down ${false ? "icon-dropdown" : "icon-arrow-1-up"}`}
            />
          </div>
        }
        controlled={false}
      >
        {option?.options?.map((subOption) => {
          return <>{renderMenuItemContent(subOption)}</>;
        })}
      </CollapsibleCard>
    );
  };

  const renderPopverMenu = () => {
    return (
      <Overlay
        show={isMediaPopoverMenuOpen || !!defaultPopoverContent}
        container={containerRef.current}
        target={targetRef.current}
        placement={placement}
        rootClose={isMediaPopoverMenuOpen}
        onHide={onClosePopover}
      >
        <Popover
          {...popoverProps}
          className={`popover-modal-wrapper media-upload-with-popup ${
            popoverProps?.className || ""
          }`}
          id={popoverId}
        >
          <Popover.Content>
            <div className="media-upload-popover-body">
              {!isMediaPopoverMenuOpen && !!defaultPopoverContent ? (
                <div className="default-popover-content">{defaultPopoverContent}</div>
              ) : (
                <>
                  {enableSearch && (
                    <Input
                      inputWrapperClassName="border-padding menu-search-input"
                      placeholder="Search"
                      value={searchTerm}
                      isSearchInput
                      onChange={setSearchTerm}
                    />
                  )}
                  <div className="scrollable-content">
                    {(enableSearch ? searchedOptions : menuOptions || []).map((option) => {
                      if (option?.options) {
                        return renderSubMenu(option);
                      }
                      return renderMenuItemContent(option);
                    })}
                  </div>
                </>
              )}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    );
  };

  return (
    <div
      className={`media-upload-with-popup-wrapper ${popoverMenuMediaWrapperClass}`}
      ref={containerRef}
    >
      {children({
        onClick: onTogglePopover,
        targetRef,
      })}
      {renderPopverMenu()}
    </div>
  );
};

PopoverMenu.defaultProps = {
  children: () => {},
  popoverProps: {
    arrowProps: {},
  },
  onMenuSelect: () => {},
  placement: "bottom-start",
  enableSearch: false,
  menuOptions: [
    {
      label: "Upload Image",
      value: "MediaUpload",
      iconClass: "icon-upload-image",
      description: "Upload an image manually from your machine.",
    },
    {
      label: "Choose from library",
      value: "ChooseLibrary",
      iconClass: "icon-upload-library",
      description: "Choose an image from the library of your previously uploaded images.",
    },
  ],
  popoverMenuMediaWrapperClass: "",
  isCollapsible: true,
};

export default PopoverMenu;
