import { routes } from "src/utilities/constants";

import { Hades } from "@onlinesales-ai/services-v2";
import { getRedirectUrl, changeClientId } from "@onlinesales-ai/app-v2/application/actions";
import { triggerMonitor } from "@onlinesales-ai/error-catcher-v2";
import { getDomainConfiguration } from "src/domainConfig/configuration";

import Types from "./types";

export const redirectUrl = getRedirectUrl(routes);

export const setFetchUserList = (isLoading) => ({
  type: Types.SET_FETCH_USER_LIST,
  isLoading,
});

export const setHasAdminAccess = (hasAdminAccess) => ({
  type: Types.SET_HAS_ADMIN_ACCESS,
  hasAdminAccess,
});

export const setAppLevelDataFetchState = (isLoading) => ({
  type: Types.SET_APP_LEVEL_DATA_FETCH_STATE,
  isLoading,
});

export const updateCustomerTargetingSetup = (userInfo) => ({
  type: Types.UPDATE_CUSTOM_TARGETING_SETUP,
  userInfo,
});

const isAdmin = (userEntityMappings, userInfo) => {
  if (Array.isArray(userEntityMappings) && userEntityMappings.length) {
    return userEntityMappings.findIndex((u) => u.id === userInfo.id) !== -1;
  }

  return false;
};

export const checkAgencyAccess = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { agencyId, userInfo, isInternalUser, isRealInternalUser } = state.Application;

    dispatch(setFetchUserList(true));

    try {
      const response = await Hades.fetchUsersList({
        entityType: "AGENCY",
        isFetchAllUsers: false,
        entityId: agencyId,
        isInternalRequest: false,
      });

      if (isInternalUser || isRealInternalUser || isAdmin(response.userEntityMappings, userInfo)) {
        dispatch(setHasAdminAccess(true));
      }
    } catch (err) {
      triggerMonitor("SEV2", "APP_LEVEL_AGENCY_ACCESS_CHECK_ERROR", {
        err,
        payload: {
          entityType: "AGENCY",
          isFetchAllUsers: false,
          entityId: agencyId,
          isInternalRequest: false,
        },
      });
      dispatch(redirectUrl(routes.LOGIN.path));
    }

    dispatch(setFetchUserList(false));
  };
};

export const changeClientIdAction = (clientId) => {
  return (dispatch) => {
    dispatch(changeClientId(clientId, getDomainConfiguration));
  };
};

