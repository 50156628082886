import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import classnames from "classnames";

import PopoverTooltip from "@onlinesales-ai/popover-tooltip-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { markDownToReactWithPlaceholder } from "@onlinesales-ai/html-v2";

const FormWrapper = ({
  title,
  titleTooltip,
  titleTooltipPlacement,
  titleGuidText,
  labelColumns,
  formGroupClassName = "",
  formGroupInnerClassName = "",
  warningicon = "",
  guidText,
  guidElement,
  guideElementMarkup,
  guidElementClass,
  hasError,
  error,
  apiError,
  children,
  warning,
  errorWarning,
  isEditable,
  infoTooltip,
  contentInfoTooltip,
  isRequired,
  labelDefinationKey,
  contentClassName,
  labelClassName,
  showEmptyTitle,
  labelBottomComponent,
  extraLabelProps,
  tooltipProps,
  alwaysShowGuide,
  secondaryLabel,
  showSecondaryLabelAsGuideText,
  doNotShowSLInNonEditMode,
  icon,
  isErrorPossible,
  errorClassName,
  hideLabelSeparator,
  innerLabelClass,
  customErrorClass,
}) => {
  const { t } = useTranslation();
  const requiredIconDOM = useMemo(() => {
    return isRequired && isEditable ? <span className="required-asterisk error-msg">&#42;</span> : null;
  }, [isRequired, isEditable]);

  const showSecondaryLabel = useMemo(() => {
    return secondaryLabel ? isEditable || !doNotShowSLInNonEditMode : false;
  }, [isEditable, secondaryLabel, doNotShowSLInNonEditMode]);

  return (
    <div
      className={`form-component-wrapper ${` ${formGroupClassName || ""} `} ${hasError ? " show-error-form " : ""} ${isEditable ? "" : " non-editable "}`}
    >
      <div className={`form-group ${formGroupInnerClassName}`}>
        {(title || showEmptyTitle || labelDefinationKey) && (
          <>
            <div className={`col-sm-${labelColumns} ${labelColumns === 12 ? "d-align-center full-line-label" : ""} info-label-wrapper padding-0 ${labelClassName || ""} ${isErrorPossible ? "" : "no-error"}`}>
              <Text
                {...extraLabelProps}
                labelDefinationKey={labelDefinationKey}
                suffix={requiredIconDOM}
                icon={icon}
                fallBackLabel={
                  <>
                    <label className="info-label os-label-wrapper">
                      <div className={`os-tooltip-text ${innerLabelClass} ${icon ? "d-align-center" : ""}`}>
                        {icon && <span className={`icon ${icon} form-label-icon mr10 lh0`} />}
                        {t(title)}
                        {title && !title.endsWith(":") && !hideLabelSeparator && labelColumns !== 12 ? ":" : ""}
                        {requiredIconDOM}
                        {infoTooltip ? (
                          <WithTooltip
                            title={<div dangerouslySetInnerHTML={{ __html: t(infoTooltip) }} />}
                            placement="auto"
                          >
                            <span className="info-icon icon icon-info-circle1 text-info-icon" />
                          </WithTooltip>
                        ) : null}
                      </div>
                    </label>
                  </>
                }
              />
              {titleGuidText ? <div className="guide-text">{t(titleGuidText)}</div> : null}
              {labelBottomComponent}
            </div>
            {showSecondaryLabel && <Text type="primary" size="small" className="mb-2" {... (showSecondaryLabelAsGuideText && { italic: true })}>{secondaryLabel}</Text>}
          </>
        )}
        <div
          className={`col-sm-${
            labelColumns === 12 ? labelColumns : 12 - labelColumns
          } inline-block padding-0 content-form-box ${contentClassName || ""}`}
        >
          {children}
          {(isEditable || alwaysShowGuide) && (guidText || guidElement) && (
            <div className={`guide-text ${guidElementClass}`}>{guidElement || t(guidText)}</div>
          )}
          {isEditable && guideElementMarkup ?
            <div className="guide-text guide-text-md">{markDownToReactWithPlaceholder(t(guideElementMarkup))}</div>
          : null}
          {isErrorPossible && (
            <div className={classnames("error-msg name-error-message", errorClassName, customErrorClass)}>
              {errorWarning && (
                <div className="warning-msg">
                  <span className={`${warningicon}`} />
                  {typeof errorWarning === "string" ? (
                    t(errorWarning)
                  ) : (
                    <Trans>{errorWarning}</Trans>
                  )}
                </div>
              )}
              {hasError && <div>{typeof error === "string" ? t(error) : error}</div>}
              {apiError && <div>{t(apiError)}</div>}
            </div>
          )}
        </div>
        {contentInfoTooltip ? (
          <WithTooltip
            title={<div dangerouslySetInnerHTML={{ __html: contentInfoTooltip }} />}
            placement="auto"
          >
            <span className="info-icon-always">
              <i className="icon icon-info-circle1" />
            </span>
          </WithTooltip>
        ) : null}
      </div>
      <div className={"warning-box-wrapper " + (warning ? "is-show" : "")}>
        {warning && (
          <div className="warning-box">
            <div className="warning-text">
              <button type="button" className="close">
                &times;
              </button>
              {warning}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FormWrapper.defaultProps = {
  extraLabelProps: {},
  tooltipProps: {
    placement: "top",
  },
  isErrorPossible: true,
  errorClassName: "",
  showSecondaryLabelAsGuideText: false,
};

export default FormWrapper;
