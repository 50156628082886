import React, { useEffect, useRef, useCallback, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _isEmpty from "lodash/isEmpty";

import { setMediaQueryDevice as setMediaQueryDeviceAction } from "@onlinesales-ai/app-v2/application";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import {
  checkToken,
  getVisitorAccountObj,
  pendoTrackEvent,
  getEntityVisitorAccountObj as getEntityVisitorAccountObjAction,
} from "@onlinesales-ai/util-methods-v2";
import { Drawer } from "@onlinesales-ai/drawer-v2";
import { OSHOCWithUtilities } from "@onlinesales-ai/os-hoc-with-utilities-v2";
import { ToastContainer } from "@onlinesales-ai/toast-v2";
import { Sentry, startSentryReplay } from "@onlinesales-ai/error-catcher-v2";
import { getCurrentLanguage } from "@onlinesales-ai/i18n-v2";
import { setUserInfo as setGAUserInfo } from "@onlinesales-ai/tracking-reporting-v2";

import {
  DetectDevice,
  PWAInit,
  loadManifestFromDomainConfig,
  ClientRating,
  FooterNote,
  newFeatureTriggerCallback,
  AppActions,
  AdBlockerNotification,
} from "./index";
import { getUserInfo, getShopInfo, populateEvent } from "./config";
import HelpSectionIframe from "./helpSection/helpSectionIframe";
import GuideProcessor from "./guideProcessor";
import ViewPortTracking from "./viewPortTracking";
import TranslationErrorDetector from "./translationErrorDetector";

const LUCKY_ORANGE_SITE_ID = "a83c7389";
const HELP_HERO_ID = "5IoR4fuMi4a";

// add this in pendo js and also for the button action you need to use aria label.
// document.querySelector/getElementById("[aria-label='test1']").addEventListener("click", () => { window.fnTCFOx({type: "PENDO_REDIRECT_PAGE", event: { url: "drawer/TRANSACTIONS"  }}); })

const fnTCFO = ({ type, event }) => {
  if (type) {
    PlatformEventManager.emit(type, { iframeUrl: event?.url, ...event });
  }
};

(() => {
  window.fnTCFOx = (props) => {
    fnTCFO(props);
  };
})();

const AddOnsHandler = ({
  isLuckyOrangeEnable,
  isDetectDevice,
  isInitPWA,
  isOnOnboardingPage,
  shopInfo: pShopInfo,
  isMobile,
  userInfo: pUserInfo,
  domainConfig,
  isInternalUser,
  setMediaQueryDevice,
  redirectUrl,
  loginUrl,
  showConfirmationModal,
  resetConfirmationModal,
  postOnboardingData,
  children,
  location,
  showToastMessage,
  isAdminAccount,
  enableEntityHades,
  getEntityVisitorAccountObj,
  selectedEntityId,
  selectedEntityType,
  agencyId,
  preferredTheme,
}) => {
  const { t } = useTranslation();
  const isLuckyOrangeInitialized = useRef(false);
  const isPendoInitialized = useRef(false);
  const isSentryReplayInitialized = useRef(false);
  const isClarityInitialised = useRef(false);
  const [helpDrawerConfig, setHelpDrawerConfig] = useState({
    isShow: false,
    iframeUrl: null,
    headerTitle: "",
  });

  const redirectUrlToCall = ({ url }) => {
    try {
      redirectUrl(url);
    } catch (error) {}
  };

  const initializeClarityTags = () => {
    if (!isClarityInitialised.current && !_isEmpty(pUserInfo) && !_isEmpty(pShopInfo) && typeof window.clarity === "function") {
      try {
        window.clarity("set", "userId", `${pUserInfo.id}`);
        window.clarity("set", "clientId", `${pShopInfo.clientId}`);
        window.clarity("set", "agencyId", `${pShopInfo.agencyId}`);
        window.clarity("set", "isInternalUser", `${isInternalUser}`);
        window.clarity("set", "platformType", `${domainConfig?.platformType}`);
        isClarityInitialised.current = true;
      } catch(e) {}
    }
  };

  useEffect(() => {
    PlatformEventManager.on("PENDO_REDIRECT_PAGE", redirectUrlToCall);

    return () => {
      window.fnTCFOx = () => {};
      PlatformEventManager.off("PENDO_REDIRECT_PAGE", redirectUrlToCall);
    };
  }, []);

  const newFeatureTagConfig = domainConfig?.newFeatureTagConfig;

  useEffect(() => {
    try {
      window.document.title = domainConfig?.pageTitle || "OnlineSales.ai";

      const link = document.querySelector("link[rel~='icon']");
      link.href = domainConfig.faviconUrl || "/favicon.ico?v=2";

      if (domainConfig?.customTheme) {
        const tempBody = document.getElementsByTagName("body")[0];
        tempBody.setAttribute("data-theme", domainConfig?.customTheme);
      }

      loadManifestFromDomainConfig(domainConfig);
    } catch (e) {}
  }, [domainConfig]);

  useEffect(() => {
    if (!_isEmpty(pUserInfo) && !_isEmpty(pShopInfo)) {
      populateEvent("APP", "App.TrackerReady", {});
    }
  }, [pUserInfo, pShopInfo]);

  useEffect(() => {
    initializeClarityTags();
    if (
      !isPendoInitialized.current &&
      !domainConfig?.disablePendoTracking &&
      window.pendo?.initialize
    ) {
      const userInfo = getUserInfo(true);
      const shopInfo = getShopInfo(true);
      window.pendo.initialize(
        enableEntityHades
          ? getEntityVisitorAccountObj({
            userInfo,
          })
          : getVisitorAccountObj({
              userInfo,
              shopInfo: {
                ...shopInfo,
                clientId: pShopInfo?.clientId
                  ? pShopInfo.clientId
                  : domainConfig.marketplaceClientId,
              },
              isInternalUser,
              domainConfig,
              isAdminAccount,
            }),
      );

      // Dismiss all previous guides to avoid peristance
      if (domainConfig?.disablePendoGuidePersistance) {
        try {
          window.pendo.onGuideDismissed();
        } catch (e) {}
      }
      isPendoInitialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (
      !domainConfig?.disablePendoTracking &&
      !_isEmpty(pUserInfo) &&
      (enableEntityHades || !_isEmpty(pShopInfo))
    ) {
      if (window.pendo?.updateOptions) {
        window.pendo.updateOptions(
          enableEntityHades
            ? getEntityVisitorAccountObj({
                userInfo: {
                  ...pUserInfo,
                  os_language: getCurrentLanguage(true),
                },
              })
            : getVisitorAccountObj({
                userInfo: {
                  ...pUserInfo,
                  os_language: getCurrentLanguage(true),
                },
                shopInfo: pShopInfo,
                storeType: domainConfig?.storeType,
                isInternalUser,
                domainConfig,
                isAdminAccount,
                preferredTheme,
              }),
        );
      }
    }

    try {
      if (!_isEmpty(pUserInfo) && (enableEntityHades || !_isEmpty(pShopInfo))) {
        Sentry.setUser({
          email: pUserInfo.email,
          userInfo: pUserInfo,
          shopInfo: pShopInfo,
          isInternalUser,
        });
        Sentry.setTag("isInternalUser", isInternalUser);
        Sentry.setTag("userId", pUserInfo.id);

        if (enableEntityHades) {
          if (selectedEntityType && selectedEntityId) {
            Sentry.setTag("entityId", `${selectedEntityType}_${selectedEntityId}`);
          } else {
            Sentry.setTag("entityId", null);
          }
        } else {
          Sentry.setTag("clientId", pShopInfo?.clientId);
        }

        Sentry.setTag("agencyId", pShopInfo?.agencyId || agencyId);
        Sentry.setTag("domain", window?.location?.hostname);

        if (
          domainConfig?.enableSentryReplay &&
          !isInternalUser &&
          ENABLE_SENTRY &&
          NODE_ENV === "production" &&
          pUserInfo?.id &&
          !isSentryReplayInitialized.current
        ) {
          startSentryReplay();
          isSentryReplayInitialized.current = true;
        }
      }
    } catch (err) {
      pendoTrackEvent("SENTRY_ERROR||SET_USER", err);
    }

    try {
      if (!_isEmpty(pUserInfo)) {
        const config = {
          userId: pUserInfo.id,
          agencyId: pShopInfo?.agencyId || agencyId,
          environment: domainConfig?.environment,
        };

        if (enableEntityHades) {
          if (selectedEntityType && selectedEntityId) {
            config.entity = `${selectedEntityType}_${selectedEntityId}`;
          }
        } else {
          config.clientId = pShopInfo?.clientId || domainConfig?.marketplaceClientId;
        }
        setGAUserInfo(config);
      }
    } catch (err) {}

    initializeClarityTags();
  }, [
    pUserInfo,
    pShopInfo,
    domainConfig?.disablePendoTracking,
    domainConfig?.storeType,
    isInternalUser,
    isAdminAccount,
    selectedEntityId,
    selectedEntityType,
    domainConfig?.enableSentryReplay,
    preferredTheme,
    domainConfig?.platformType,
  ]);

  const onUATokenInvalid = useCallback(() => {
    showConfirmationModal({
      isShow: true,
      modalType: "ALERT",
      title: t(`You have been logged out from all the devices. Please try logging in again.`),
      actionBtnText: "OK",
      rightBtnText: "",
      actionBtnCallback: () => {
        resetConfirmationModal();
        redirectUrl(loginUrl);
      },
    });
  }, []);

  const onVersionOutdated = async () => {
    pendoTrackEvent("UI_VERSION_REFRESH_TRIGGERED");
    try {
      await fetch("/index.html", {
        headers: {
          Pragma: "no-cache",
          Expires: "-1",
          "Cache-Control": "no-cache",
        },
      });
      window.top.location.reload(true);
    } catch (err) {
      pendoTrackEvent("UI_VERSION_REFRESH_ALERT_SHOW");
      showConfirmationModal({
        isShow: true,
        modalType: "ALERT",
        title: t(
          `Hey There, we have upgraded our system. Please refresh the app for the seamless experience.`,
        ),
        actionBtnText: "",
        rightBtnText: "",
        actionBtnCallback: () => {},
      });
    }
  };

  const onOpenHelpDrawer = useCallback((data) => {
    setHelpDrawerConfig({
      isShow: true,
      ...data,
    });
  }, []);

  useEffect(() => {
    window._sokEvents = {
      populateEvent,
    };

    PlatformEventManager.on("INVALID_UA_TOKEN", onUATokenInvalid);
    PlatformEventManager.on("OPEN_HELP_DRAWER", onOpenHelpDrawer);

    if (domainConfig?.enableDeploymentRefresh) {
      PlatformEventManager.on("VERSION_OUTDATED", onVersionOutdated);
    }
  }, [domainConfig]);

  const startLuckyOrange = () => {
    const userInfo = getUserInfo();
    const shopInfo = getShopInfo();
    window.__lo_site_id = LUCKY_ORANGE_SITE_ID;
    if (!isLuckyOrangeInitialized.current && !_isEmpty(userInfo) && !_isEmpty(shopInfo)) {
      try {
        const wa = document.createElement("script");
        wa.type = "text/javascript";
        wa.async = true;
        wa.src = `https://tools.luckyorange.com/core/lo.js?site-id=${LUCKY_ORANGE_SITE_ID}`;
        const s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(wa, s);

        const customData = {
          clientId: shopInfo.clientId,
          userId: userInfo.id,
          email: userInfo.email,
          contact: userInfo.contactNo,
          url: shopInfo.url,
          storeType: domainConfig.storeType,
          clientName: shopInfo.name,
        };

        window._loq = window._loq || [];
        window._loq.push(["custom", customData]);
        isLuckyOrangeInitialized.current = true;
      } catch (e) {}
    }
  };

  const initLuckyOrange = () => {
    if (isLuckyOrangeEnable && (pUserInfo.email || pUserInfo.contactNo) && !isInternalUser) {
      startLuckyOrange();

      PlatformEventManager.on("SIGN_OUT", () => {
        try {
          window.LO.Session.reset();
        } catch (e) {}
      });
    }
  };

  useEffect(() => {
    if (pUserInfo) {
      if(window.beamer_config && typeof window.beamer_config === "object") {
        window.beamer_config.user_firstname = pUserInfo?.name;
        window.beamer_config.user_email = pUserInfo?.email;
        window.beamer_config.user_id = pUserInfo?.id;
      }
      initLuckyOrange();
    }
  }, [pUserInfo]);

  useEffect(() => {
    // trigger function once initially and then retrigger on dom changes
    if (newFeatureTagConfig?.selectors?.length) {
      newFeatureTriggerCallback(newFeatureTagConfig);
    }
  }, []);

  useEffect(() => {
    let observer;
    if (newFeatureTagConfig?.selectors?.length) {
      const observedDOMNode = document.body;

      // Options for the observer (which mutations to observe)
      const config = { childList: true, subtree: true, attributes: true };

      // Create an observer instance linked to the callback function
      observer = new MutationObserver(() => {
        newFeatureTriggerCallback(newFeatureTagConfig);
      });

      // Start observing the target node for configured mutations
      observer.observe(observedDOMNode, config);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [newFeatureTagConfig]);

  const isLoggedIn = checkToken();
  return (
    <>
      <TranslationErrorDetector />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        toastClassName
        // closeButton={false}
      />
      <Drawer
        isOpen={helpDrawerConfig.isShow}
        onClickClose={() => {
          setHelpDrawerConfig({
            isShow: false,
            iframeUrl: null,
          });
        }}
      >
        <HelpSectionIframe {...helpDrawerConfig} />
      </Drawer>
      {isDetectDevice && <DetectDevice onChange={setMediaQueryDevice} />}
      {isInitPWA && <PWAInit />}
      {!!domainConfig?.showRating &&
        !isInternalUser &&
        isLoggedIn &&
        !_isEmpty(pUserInfo) &&
        !_isEmpty(pShopInfo) && (
          <ClientRating
            showToastMessage={showToastMessage}
            isOnOnboardingPage={isOnOnboardingPage}
          />
        )}
      {!!domainConfig?.footerNoteHtml && (
        <FooterNote footerContent={domainConfig?.footerNoteHtml} />
      )}
      {children}
      <AppActions
        location={location}
        appActionConfig={domainConfig?.appActionConfig}
        actionList={domainConfig?.actionList}
        postOnboardingData={postOnboardingData}
      />
      <GuideProcessor />
      <ViewPortTracking />
      {domainConfig.showAdBlockerNotification && <AdBlockerNotification showToastMessage={showToastMessage} />}
    </>
  );
};

AddOnsHandler.defaultProps = {
  children: null,
  isLuckyOrangeEnable: false,
  isDetectDevice: true,
  isAdminAccount: false,
  isInitPWA: true,
  isOnOnboardingPage: false,
  loginUrl: "/login",
  showConfirmationModal: () => {},
  resetConfirmationModal: () => {},
  redirectUrl: () => {},
};

const mapStateToProps = (state) => {
  return {
    shopInfo: state.Application.shopInfo,
    isMobile: state.Application.isMobile,
    userInfo: state.Application.userInfo,
    agencyId: state.Application.agencyId,
    domainConfig: state.DomainConfig,
    isInternalUser: state.Application.isRealInternalUser,
    enableEntityHades: state.DomainConfig?.enableEntityHades,
    selectedEntityId: state.Application.selectedEntityId,
    selectedEntityType: state.Application.selectedEntityType,
    preferredTheme: state.Application?.preferredTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setMediaQueryDevice: setMediaQueryDeviceAction,
      getEntityVisitorAccountObj: getEntityVisitorAccountObjAction,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(OSHOCWithUtilities(AddOnsHandler)));
