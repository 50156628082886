import { useEffect, useRef } from "react";
import _isEqual from "lodash/isEqual";

import { Sentry } from "./sentry";

class UIMonitor extends Error {
  constructor(message) {
    super(message);
    this.name = "UIMonitor";
  }
}

const ignoreErrors = ({ error }) => {
  if (!error) {
    return true;
  }

  const errorCodes = ["AD0000", "UNAUTHORIZED", "ACCESS_DENIED", "RSE_UNAUTHORIZED"];

  return (
    (error?.errorType === "TypeError" && error?.errorMsgTitle === "Error while fetching data") ||
    (error?.err?.name === "TypeError" && error?.err?.message === "Failed to fetch") ||
    error?.isAborted || errorCodes.includes(error?.errorCode)
  );
};

export const triggerMonitor = (severity, eventName, payload = {}, level = "error") => {
  try {
    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setTag("severity", severity);
      scope.setTag("from", "UI_MONITOR");
      scope.setExtra("metadata", payload);

      Sentry.captureException(new UIMonitor(`${severity}_${eventName}`.toUpperCase()));
    });
    // for local dev
    console.log("REPORT_ERROR", severity, eventName, payload);
  } catch (sentryError) {}
};

export const uiAPIMonitor = (severity, eventName, payload = {}, level = "error") => {
  if (ignoreErrors(payload)) {
    // for local dev
    console.log("ERROR_REPORTING_SKIPPED", severity, eventName, payload);
    return;
  }

  triggerMonitor(severity, eventName, payload, level);
};

export const useTimeBasedMonitor = (
  { data, dataShouldBe, checkCondition, dependancy },
  delay,
  ...args
) => {
  const timerRef = useRef(null);

  useEffect(() => {
    // Check if the data has changed
    // Clear the previous timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer
    timerRef.current = setTimeout(() => {
      const isDataMatch = _isEqual(data, dataShouldBe);
      let extraCheck = true;

      if (!isDataMatch && typeof checkCondition === "function") {
        extraCheck = checkCondition(dataShouldBe);
      }

      if (!isDataMatch && extraCheck) {
        triggerMonitor(...args);
      }
    }, delay);

    // Cleanup timer on unmount or if delay changes
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [data, dependancy]);

  return null;
};
